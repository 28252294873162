// ，修正状态（此状态主要用来听声音，以及是否上传）
import { Button, Divider, Flex, Image, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import playIcon from "./svg/play.svg";
import stopIcon from "./svg/stop.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import { addZero, config, formattedTime } from "@chat/shared";
import { useEffect, useMemo, useRef, useState } from "react";
import WaveSurfer from 'wavesurfer.js';
import flyIcon from "@/assets/message/fly.png";
import { createAudioMessage } from "@chat/zim-sdk";
import useSendMessage from "@/hooks/useSendMessage.ts";
import { useParams } from "react-router-dom";
import { emitter } from "@/utils";
import { uid } from "uid";
import { showRecord } from "@/store/slice/voice.ts";
import { useDebounceFn } from "ahooks";

function Review() {
    const { reviewVisible } = useAppSelector(state => state.voice);
    const dispatch = useAppDispatch();
    const { conversationID = "" } = useParams();
    const container = useRef<HTMLDivElement>(null);
    const wavePlayRef = useRef<WaveSurfer>();
    const { sendMessage } = useSendMessage();
    const [isPlay, setIsPlay] = useState(false);
    const [time, setTime] = useState<(number | string)[]>(["00", "00"]);
    const [duration, setDuration] = useState(0);
    const totoalDuration = useRef(0);
    const [localFile, setLocalFile] = useState<Blob>();
    const sendVoiceMessage = async (file: File, duration: number) => {
        console.log(duration);
        const message = createAudioMessage(file, duration);
        await sendMessage({
            message,
            conversationID: conversationID
        });
        // 清空引用
    };
    const file = useMemo(() => {
        if (!localFile) return null;
        return new File([localFile], uid() + ".mp3");
    }, [localFile]);
    const handlePlay = () => {
        setIsPlay(!isPlay);
        wavePlayRef.current?.playPause();
    };
    // duration url
    useEffect(() => {
        if (!reviewVisible || !localFile) return;
        const recordedUrl = URL.createObjectURL(localFile);
        const defaultOptions = {
            ...config.wave,
            waveColor: "#c7ccd4",
            progressColor: "#848FA0",
            height: 20,
            url: recordedUrl,
            container: container.current!,
        };
        if (wavePlayRef.current) {
            wavePlayRef.current.destroy();
        }

        wavePlayRef.current = WaveSurfer.create(defaultOptions);
        wavePlayRef.current.on("decode", () => {
            const total = Math.ceil(wavePlayRef.current!.getDuration()) * 1000;
            const [t1, t2] = formattedTime(total);
            totoalDuration.current = total;
            setTime([t1, addZero(t2)]);

        });
        // 不能相信finish的回调， 加一个进度监听用来关闭
        wavePlayRef.current.on("audioprocess", (percent) => {
            setDuration(percent * 1000);
            if (percent > totoalDuration.current) {
                wavePlayRef.current?.setTime(0);
                wavePlayRef.current?.stop();
            }
        });
        wavePlayRef.current.on("finish", () => {
            setIsPlay(false);
            wavePlayRef.current?.setTime(0);
        });

    }, [localFile, reviewVisible]);
    useEffect(() => {
        emitter.on("VOICE_END", ({ blob }) => {
            setLocalFile(blob);
        });
        return () => {
            emitter.off("VOICE_END");
        };
    }, []);
    const { run } = useDebounceFn(() => {
        dispatch(showRecord());
        sendVoiceMessage(file!, Math.ceil(totoalDuration.current / 1000))
    }, { wait: 1000 });
    return <Flex className="voice-review" align="center" gap={ 4 }
                 style={ { display: reviewVisible ? "flex" : "none" } }>
        <Button className="voice-track-handler" type="text" size="small" icon={ <DeleteOutlined/> }
                onClick={ () => dispatch(showRecord()) }></Button>
        <Flex className="voice-play-box" align="center" gap={ 4 }>
            <img className="icon" src={ isPlay ? stopIcon : playIcon } alt="" onClick={ handlePlay }/>
            <div className="voice-wave" ref={ container }></div>
            <Space>
                <span>{ formattedTime(duration).join(":") }</span>
                <span>/</span>
                <span>{ time.join(":") }</span>
            </Space>
        </Flex>
        <Divider type="vertical" style={ { fontSize: 18 } }/>
        <Image
            className="voice-send"
            src={ flyIcon }
            preview={ false }
            onClick={ run }
        />
    </Flex>;
}

export default Review;
