import { Modal, Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "@/store";
import { useEffect } from "react";
import { closePasswordModal } from "@/store/slice/modal.ts";
import store2 from "store2";
import { applyPasswordRoom } from "@/api/room";
import useChatInit from "@/hooks/useChatInit.ts";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";


function PasswordModal() {
    const [form] = Form.useForm();
    const { chatInit } = useChatInit();
    const { passwordModal } = useAppSelector(state => state.model);
    const navigate = useNavigate()
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (passwordModal.visible) {
            // 清除
            form?.resetFields();
        }
    }, [passwordModal.visible]);
    const handleClose = () => {
        dispatch(closePasswordModal());
        navigate("/chat/message", {
            replace: true
        })
    };
    const { loading, run } = useRequest(applyPasswordRoom, {
        onSuccess(res) {
            if (res.code === 0) {
                dispatch(closePasswordModal());
                chatInit();
            } else {
                form.setFields([{
                    errors: [res.msg],
                    name: "password"
                }]);
            }
        },
        onError() {

        }
    });
    const handleOk = (fields: any) => {
        run({
            groupId: passwordModal.groupId,
            imUserId: store2.get("userID"),
            groupPass: fields.password
        });
    };
    return <Modal width={ 380 }
                  forceRender
                  onCancel={ handleClose }
                  title="Please Enter The Password"
                  open={ passwordModal.visible }
                  modalRender={ (dom) => {
                      return <Form form={ form } onFinish={ handleOk }>
                          { dom }
                      </Form>;
                  } }
                  okButtonProps={ { autoFocus: true, htmlType: 'submit', loading: loading } }
    >
        <Form.Item label="Password" name="password"
                   rules={ [
                       { required: true, message: "Please input The Password" },
                   ]
                   }>
            <Input.OTP length={ 6 }/>
        </Form.Item>
    </Modal>;
}

export default PasswordModal;
