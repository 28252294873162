import { createSlice } from "@reduxjs/toolkit";
import { ZIMVideoMessage } from "@chat/zim-sdk";

const initialState = {
    videoModal: {
        visible: false,
        videoElem: {} as ZIMVideoMessage
    },
    passwordModal: {
        visible: false,
        groupId: "",
    }
};
export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showVideoModal(state, action) {
            state.videoModal = {
                visible: true,
                videoElem: action.payload
            };
        },
        hiddenVideoModal(state) {
            state.videoModal = {
                ...state.videoModal,
                visible: false,
            };
        },
        openPasswordModal(state, action) {
            const payload = action.payload;
            state.passwordModal = {
                visible: true,
                groupId: payload.groupId
            }
        },
        closePasswordModal(state) {
            state.passwordModal = {
                visible: false,
                groupId: ""
            }
        },
    }
});

export const { showVideoModal, hiddenVideoModal, openPasswordModal, closePasswordModal } = modalSlice.actions;
export default modalSlice.reducer;
