import { ExpressType } from "./type";
import cls from "./imageTool.module.scss";
import { hasFileExtension } from "@chat/shared";
import { useRef } from "react";
import { getGifPage } from "@/api/im";
import { Spin } from "antd";
import { WaterFall } from "@chat/component";
import axios from "axios";

type GifBoxProps = {
    chooseItem: (type: ExpressType, url: string) => void;
}
export default function GifBox(props: GifBoxProps) {
    const { chooseItem } = props;

    const paginate = useRef(
        {
            pageSize: 20,
            totalSize: 0,
            currentPage: 0,
            totalPage: 0
        }
    );
    const requestGifData = async (pageNo: number, pageSize: number) => {
        if (paginate.current.currentPage !== 0 && pageNo >= paginate.current.totalPage) {
            return;
        }
        const { code, data } = await getGifPage({
            pageSize,
            pageNo
        });
        if (code === 0) {
            const { totalPages, content, totalSize } = data;
            // 获取到数据，获取图片信息
            const list = content.map((item: any) => axios.get(item.imgUrl + "?x-oss-process=image/info"));
            const infos = await Promise.all(list);
            const imageInfos = infos.map((info, i) => {
                const { ImageHeight, ImageWidth } = info.data;
                // 只对gif做处理
                const url = content[i].imgUrl;
                const isGif = hasFileExtension(url, "gif");
                return {
                    ...content[i],
                    url: isGif ? url + "?x-oss-process=image/resize,w_80,m_lfit" : url,
                    width: parseInt(ImageWidth.value),
                    height: parseInt(ImageHeight.value)
                };
            });
            paginate.current = {
                pageSize: pageSize,
                totalSize,
                currentPage: pageNo,
                totalPage: totalPages
            };
            return imageInfos;
        }
    };

    return <div className={ cls.gifBox }>
        <WaterFall columnNum={ 4 }
                   gap={ 4 }
                   pageSize={ 20 }
                   request={ requestGifData }
                   onCardClick={ (item: any) => chooseItem(ExpressType.GIF, item.imgUrl) }
                   loadingSlot={ <Spin size="small" tip="loading" style={{top: 20}}>
                   </Spin> }
        >
        </WaterFall>
    </div>;
}
