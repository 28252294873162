import { ChangeEvent, useRef } from "react";
import useFileMessage from "@/hooks/useFileMessage.ts";
import useSendMessage from "@/hooks/useSendMessage.ts";
import { ImageTool } from "@/components/icon";
import { useParams } from "react-router-dom";
import { BaseToolProps } from "@/pages/Message/feature/Conversation/Tool/type.ts";
import classNames from "classnames";

function FileUpdate(props: BaseToolProps) {
    const {disabled} = props
    const fileRef = useRef<HTMLInputElement>(null);
    const { createImageOrVideoMessage } = useFileMessage();
    const { sendMessage } = useSendMessage();
    const {conversationID = ""} = useParams()
    const handlerClick = () => {
        if (disabled) return;
        fileRef.current?.click();
    };
    const listenFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files![0];
        e.target.value = "";
        createImageOrVideoMessage(file).then(message => {
            // 发送消息
            sendMessage({
                message,
                conversationID
            });
        });
    };

    return <div className="tools-item">
        <ImageTool onClick={ handlerClick } className={classNames("tools-item", disabled && "tools-item-disable")}
        ></ImageTool>
        <input style={ { display: "none" } } onChange={ listenFileChange } ref={ fileRef } type="file"
               accept="image/*,video/*"/>
    </div>;
}

export default FileUpdate;
