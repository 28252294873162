import cls from "./styles/prediction.module.scss";
import { Image } from "antd";
import { renderForecastResults } from "@chat/component";
import { ZIMCustomMessage } from "@chat/zim-sdk";
import { parseStr } from "@chat/shared";
import dayjs from "dayjs";
import MessageState from "../MessageItem/MessageState";
export type EventResult = {
    bigOrSmall: 1 | 2 | 0;  // * 1.大  2.小  0.豹子
    oddOrEven: 1 | 2 | 0;  // * 1.单odd  2.双  0.豹子
    totalCount: number;

    // dice 3d
    one: number;
    two: number;
    three: number
    // color
    number: number;
    oneColor: "r";
    twoColor: "v";
    // threeColor: "g";
}
export type EventCardItem = {
    sourceId: number;
    gameType: "dice" | "3digit" | "color";
    forecastRate: number;
    groupId: string;
    drawTime: number;
    packageId: number;
    updateTime: number;
    issueNo: number;
    gameIcon: string;
    cycle: number;
    forecastResults: string;
    gameName: string;
    createTime: number;
    id: number;
    status: number
}
type PredictiveCardProps = {
    data: ZIMCustomMessage
}

function PredictionMessage(props: PredictiveCardProps) {
    const { data } = props;
    const cardData = parseStr<EventCardItem>(data.message);
    return (
        <>
            <div className={ cls.container }>
                <div className={ cls.detail }>
                    <Image src={ cardData.gameIcon } className={ cls.pic } preview={false}/>
                    <div className={ cls.detail__info }>
                        <div className={ cls.name }>{ cardData.gameName }</div>
                        <div className={ cls.id }>{ cardData.issueNo }</div>
                    </div>
                    <div className={ cls.detail__time }>
                        <div className={ cls.drawText }>Draw time</div>
                        <div className={ cls.drawTime }>{ dayjs(cardData.drawTime).format("DD-MM-YY hh:mm:ss") }</div>
                    </div>
                </div>
                <div className={ cls.forecast }>
                    <div className={ cls.forecast__item }>
                        <div className={ cls.forecast__item__title }>forecast accuracy:</div>
                        <div className={ cls.forecast__item__value }>{ Math.floor((cardData.forecastRate * 100)) }%
                        </div>
                    </div>
                    <div className={ cls.forecast__item }>
                        <div className={ cls.forecast__item__title }>forecast results:</div>
                        <div className={ cls.forecast__item__result }>
                            {
                                // 通过不同的游戏类型，展示不同的预测结果
                                renderForecastResults({
                                    result: parseStr<EventResult>(cardData.forecastResults),
                                    gameType: cardData.gameType
                                })
                            }

                        </div>
                    </div>
                </div>
            </div>
            <MessageState data={ props.data } className="message-state__bottom" style={ { padding: 0 } }/>
        </>
    );
}

export default PredictionMessage;
