import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    MessageType,
    SendMessageProps,
    ZIMMessage,
    sendMessage as sendSDKMessage,
    MessageStatus,
    getPicInfo,
    getConversationType,
    ConversationType,
} from "@chat/zim-sdk";
import { emitter } from "@/utils";
import {
    addNewMessage,
    updateMessage,
    setQuoteMessage,
    updateInputText, updateMessageRead,
} from "@/store/slice/message";
import _ from "lodash";
import { App } from "antd";
import { useUpdateRoomLastMsg } from "@/hooks/useUpdateRoomLastMsg.ts";
import { sendFbMessage } from "@/api/im.ts";

export default function useSendMessage() {
    const { message: antMessage } = App.useApp();
    const conversationDetail = useAppSelector(state => state.message.currentConversation);
    const { updateRoomLastMsg } = useUpdateRoomLastMsg();
    const dispatch = useAppDispatch();
    // 信息上传进度
    const onMediaUploadingProgress = (message: ZIMMessage, currentFileSize: number, totalFileSize: number) => {
        console.log(_.cloneDeep(message), currentFileSize, totalFileSize, "progress");
        // 更新进度
    };
    // 客户端生成消息，服务端生成消息之前
    const onMessageAttached = (message: ZIMMessage) => {
        const localMessage: any = _.cloneDeep(message);
        // 判断是否是图片
        if (message.type === MessageType.Image && localMessage.fileLocalPath) {
            getPicInfo((localMessage as any).fileLocalPath).then(res => {
                (localMessage as any).fileLocalPath = "";
                (localMessage as any).thumbnailWidth = res.width;
                (localMessage as any).thumbnailHeight = res.height;
                dispatch(addNewMessage(localMessage));
                emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
            });
        } else if ([MessageType.Video, MessageType.Audio].includes(message.type as any) && localMessage.fileLocalPath) {
            (localMessage as any).fileLocalPath = "";
            dispatch(addNewMessage(localMessage));
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
        } else {
            dispatch(addNewMessage(localMessage));
            emitter.emit("CHAT_LIST_SCROLL_TO_BOTTOM", { behavior: "smooth" });
        }
    };
    const sendMessage = useCallback(
        async ({ conversationID, message, originalMessage }: SendMessageProps) => {
            // 判断当前用户是否存在权限发送信息
            try {
                // 清空数据
                dispatch(updateInputText(''));
                // 清空引用
                dispatch(setQuoteMessage(null));
                const res = await sendSDKMessage({
                    message,
                    originalMessage,
                    conversationID,
                    notification: {
                        onMediaUploadingProgress,
                        onMessageAttached
                    },
                    fbFetch: sendFbMessage
                });
                const isRoom = getConversationType(conversationID) === ConversationType.Room;
                // 如果是房间消息，直接更新状态
                dispatch(updateMessage({
                    message: res.message,
                    key: "localMessageID"
                }));
                // handleFireBase({
                //     ...conversationDetail,
                //     conversationAvatarUrl: selfInfo!.userAvatarUrl
                // }, message);
                if (isRoom) {
                    setTimeout(() => {
                        dispatch(updateMessageRead([res.message.messageID]));
                    }, 5 * 1000);
                }
                // 判断是否是房间信息，如果是的话，需要手动更新房间的最后一条信息
                updateRoomLastMsg(res?.message);
                return res?.message;
            } catch (error: any) {
                if (error.code === 6000230) {
                    antMessage.error("Contains sensitive words");
                } else {
                    antMessage.error(error.message);
                }
                dispatch(updateMessage({
                    message: { ...message, sentStatus: MessageStatus.Failed },
                    key: "localMessageID"
                }));
            }
        },
        [conversationDetail],
    );

    return {
        sendMessage,
    };
}
