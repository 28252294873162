import cls from "@/pages/Message/feature/Setting/setting.module.scss";
import { Avatar, Button, Flex, Image, Space, Spin, Tooltip, Typography } from "antd";
import closeIcon from "@/assets/svg/close.svg";
import { copyableProps } from "@/utils";
import { useAppDispatch, useAppSelector } from "@/store";
import { setChatSettingVisible, setCurrentPerson, setMessageList } from "@/store/slice/message.ts";
import { EditOutlined } from "@ant-design/icons";
import { addFriend, checkFriendsRelation, updateFriendAlias, ZIMFriendInfo, deleteAllMessage } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";
import VipRect from "@/components/VipRect";
import { useEffect, useRef, useState } from "react";
import { getUserRecharge, RechargeProps } from "@/api/im";

const { Paragraph } = Typography;
const getNumberText = (val: string) => {
    return val.replace(/[^0-9]/g, "");
};
export default function Person() {
    const currentPerson = useAppSelector(state => state.message.currentPerson);
    const currentSettingLoading = useAppSelector(state => state.message.currentSettingLoading);
    const { conversationID } = useParams();
    const settingRef = useRef<HTMLDivElement>(null);
    const [rechargeData, setRechargeData] = useState<RechargeProps>({
        rechargeCount: 6,
        rechargeFailCount: 5,
        rechargeFailCount5: false,
        rechargeChannelNames: []
    });
    const dispatch = useAppDispatch();
    const handleClose = () => {
        dispatch(setChatSettingVisible(false));
    };
    const thirdID = getNumberText(currentPerson?.userID || "");
    const updateFriendInfo = (friendInfo: ZIMFriendInfo) => {
        dispatch(setCurrentPerson({
            ...currentPerson,
            ...friendInfo,
        }));
    };
    const deleteMessage = () => {
        deleteAllMessage(conversationID!).then(() => {
            dispatch(setMessageList([]));
        });
    };
    const handleEditNickName = async (val: string) => {
        if (currentPerson?.userID) {
            // 判断是否是好友
            const isFriend = await checkFriendsRelation(currentPerson.userID);
            if (isFriend) {
                // 如果是好友，进行更新备注
                const { friendInfo } = await updateFriendAlias(currentPerson.userID, val);
                updateFriendInfo(friendInfo);
            } else {
                // 如果不是好友，先添加好友，并增加备注
                const { friendInfo } = await addFriend(currentPerson.userID, val);
                updateFriendInfo(friendInfo);
            }
        }
    };
    const basiceInformation = [
        {
            label: "Nick Name:",
            render: () => {
                return <Paragraph
                    // className={ cls.card_item }
                    style={ { margin: 0, maxWidth: 140, color: "#4E5358" } }
                    editable={ {
                        enterIcon: null,
                        icon: <EditOutlined style={ { color: "#4E5358", fontSize: 12 } }/>,
                        onChange: handleEditNickName,
                        tooltip: 'Click to edit Nickname',
                    } }>{ currentPerson?.friendAlias || currentPerson?.userName }</Paragraph>;
            },
        },
        {
            label: "Login platform:",
            value: currentPerson?.appType === "999" ? "H5" : "APP"
        },
        {
            label: "User Grouping:",
            value: currentPerson?.deptName
        },
        {
            label: "Phone:",
            copyable: true,
            value: currentPerson?.userPhone
        }
    ];
    const registration = [
        {
            label: "Registration Channel:",
            value: currentPerson?.channelId
        },
        {
            label: "Register City:",
            value: currentPerson?.registerCityArea
        },
        {
            label: "Register Platform:",
            value: currentPerson?.platform
        },
    ];
    const recharge = [
        {
            label: "Daily recharge count:",
            value: rechargeData.rechargeCount,
        },
        {
            label: "Daily recharge failures:",
            value: <span
                style={ { color: (rechargeData.rechargeFailCount / rechargeData.rechargeCount > 0.8 || rechargeData.rechargeFailCount5) ? "#DF000C" : "initial" } }>{ rechargeData.rechargeFailCount }</span>
        }
    ];
    useEffect(() => {
        if (conversationID) {
            settingRef.current?.scrollTo({
                top: 0
            });
            getUserRecharge({ imUserId: conversationID }).then(res => {
                setRechargeData(res.data);
            });
        }
    }, [conversationID]);
    return <div className={ cls.setting } ref={ settingRef }>
        <div className={ cls.header }>
            <div className={ cls.header__text }>
                User information
            </div>
            <Image wrapperClassName={ cls.header__close } onClick={ handleClose } src={ closeIcon } preview={ false }/>
        </div>

        <div className={ cls.person }>
            <Spin spinning={ currentSettingLoading }>
                <Flex className={ cls.card } gap={ 8 } align="center" vertical style={ { textAlign: "center" } }>
                    <Avatar size={ 80 }
                            src={ currentPerson?.userAvatar }>{ currentPerson?.friendAlias || currentPerson?.userName }</Avatar>
                    <Flex vertical gap={ 4 }>
                        <Flex className={ cls.nickName } align="center" gap={ 4 }>
                            <div>
                                { currentPerson?.friendAlias || currentPerson?.userName }
                            </div>
                            <VipRect level={ currentPerson?.level || 0 } style={ { fontSize: 0 } }></VipRect>
                        </Flex>
                        <Flex gap={ 4 } className={ cls.normalText }>
                            <div>User Name:</div>
                            <Paragraph className={ cls.normalText }
                                       style={ { display: "flex", margin: 0, color: "#848FA0" } }
                                       copyable={ copyableProps(currentPerson?.userName!) }>{ currentPerson?.userName }</Paragraph>
                        </Flex>
                        <Flex gap={ 4 } className={ cls.normalText }>
                            <div>User ID:</div>
                            <Paragraph style={ { display: "flex" } }
                                       className={ cls.normalText }
                                       copyable={ copyableProps(thirdID) }>{ thirdID }</Paragraph>
                        </Flex>
                    </Flex>
                </Flex>
                <div className={ cls.gapCell }></div>
                <div className={ cls.card }>
                    <div className={ cls.title }>Basic Information</div>
                    {
                        basiceInformation.map(item => {
                            return <Flex gap={ 4 } justify="space-between" className={ cls.card_item }
                                         key={ item.label }>
                                <div>{ item.label }</div>
                                {
                                    item.render ? item.render() : item.copyable ?
                                        <Paragraph style={ {
                                            display: "flex",
                                            alignItems: "center",
                                            margin: 0,
                                            color: "#4E5358"
                                        } }
                                                   copyable={ copyableProps(item.value!) }>{ item.value }</Paragraph> :
                                        <Tooltip title={ item.value }>
                                            <div>{ item.value }</div>
                                        </Tooltip>
                                }
                            </Flex>;
                        })
                    }
                </div>
                <div className={ cls.gapCell }></div>
                <div className={ cls.card }>
                    <div className={ cls.title }>Recharge Information</div>
                    {
                        recharge.map(item => {
                            return <Flex gap={ 4 } justify="space-between" className={ cls.card_item }
                                         key={ item.label }>
                                <div>{ item.label }</div>
                                <div>{ item.value }</div>
                            </Flex>;
                        })
                    }
                    {
                        rechargeData.rechargeChannelNames?.length ? <div className={ cls.channels }>
                            <div className={ cls.title }>Payment channels</div>
                            <Space split="|" wrap style={ { marginTop: 8 } }>
                                {
                                    rechargeData.rechargeChannelNames?.map(item => {
                                        return <span key={ item }>{ item }</span>;
                                    })
                                }
                            </Space>
                        </div> : null
                    }
                </div>
                <div className={ cls.gapCell }></div>
                <div className={ cls.card }>
                    <div className={ cls.title }>Registration</div>
                    {
                        registration.map(item => {
                            return <Flex gap={ 4 } justify="space-between" className={ cls.card_item }
                                         key={ item.label }>
                                <div>{ item.label }</div>
                                {/*
                                   <Paragraph className={ cls.value }
                                    copyable={ copyableProps(thirdID) }>{ thirdID }</Paragraph>
                                */ }
                                <Tooltip title={ item.value }>
                                    <div>{ item.value }</div>
                                </Tooltip>
                            </Flex>;
                        })
                    }
                    <Flex align="center" onClick={ deleteMessage } style={ { marginTop: 64 } }>
                        <Button danger block>Clear chat history</Button>
                    </Flex>
                </div>


            </Spin>
        </div>
    </div>;
}
