export default {
    common: {
        title: "IM Management Dashboard",
        message: "message",
        group: "group",
        groupChat: "Group Chat",
        friend: "friend",
        image: "image",
        review: "review",
        setting: "setting",
        refresh: "Refresh",
        Notsupported: "Not supported",
        predictiveEvents: "predictive events",
        loginSuccess: "Logged in successfully",
        loginError:"Login failed. Only customer service can log in",
        empty: "Please select a conversation to start chatting"
    },
    login: {
        loginAccount: "Please enter your account",
        loginPassword: "Please enter your password",
        title: "Login Account",
        loginIn: "login in",
    }
};
