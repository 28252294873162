import "./style.scss";
import Record from "./Record.tsx";
import Review from "./Review.tsx";

function VoiceBox() {
    return <div>
        <Record/>
        <Review/>
    </div>;
}

export default VoiceBox;
