import mitt from 'mitt';
import { ZIMUserStatus } from "@chat/zim-sdk";

type Events = {
    CHAT_LIST_SCROLL_TO_BOTTOM: {behavior?: "smooth" | "auto", index?: number};
    CHAT_LIST_SCROLL_BY_SHOW_BTN: void;
    INPUT_FOCUS: void;
    VOICE_END: {blob: Blob, duration: number};
    USER_STATUES_UPDATED: ZIMUserStatus[]
};

const emitter = mitt<Events>();


export default emitter;
