import { createSlice } from "@reduxjs/toolkit";

enum RecordState {
    START,
    PAUSE,
}

const initialState = {
    recordVisible: true,
    reviewVisible: false,
    state: RecordState.START
};

export const voiceSlice = createSlice({
    name: "voice",
    initialState,
    reducers: {
        showRecord(state) {
            state.recordVisible = true;
            state.reviewVisible = false;
        },
        showReview(state) {
            state.recordVisible = false;
            state.reviewVisible = true;
        },
        closeRecord(state){
            state.recordVisible = false;
            state.reviewVisible = false;
        },
        reset() {
            return initialState;
        }
    }
});

export const {
    showReview,
    showRecord,
    closeRecord
} = voiceSlice.actions;
export default voiceSlice.reducer;
