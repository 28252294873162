import { Flex } from "antd";
import { useAppDispatch, useAppSelector } from "@/store";
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js';
import { useEffect, useRef, useState } from "react";
import { config, formattedTime } from "@chat/shared";
import { useLatest } from "ahooks";
import { emitter } from "@/utils";
import { showReview } from "@/store/slice/voice.ts";

enum RecordState {
    START,
    PAUSE,
}

function VoiceRecord() {
    const { recordVisible } = useAppSelector(state => state.voice);
    const dispatch = useAppDispatch();
    const [duration, setDuration] = useState(0);
    const durationRef = useLatest(duration);
    const waveRecordRef = useRef<any>(null);
    const waveSurferRef = useRef<WaveSurfer>();
    const recordBox = useRef<HTMLDivElement>(null);
    const [recordState, setRecordState] = useState<RecordState>(RecordState.START);
    const handleRecord = () => {
        if (recordState === RecordState.START) {
            waveRecordRef.current.startRecording({ deviceId: "default" });
            setRecordState(RecordState.PAUSE);
        } else {
            handleStopRecord();
        }
    };
    const handleStopRecord = () => {
        waveRecordRef.current.stopRecording();
        setRecordState(RecordState.START);
    };
    const createWaveSurfer = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.destroy();
        }
        const waveSurfer = WaveSurfer.create({
            ...config.wave,
            waveColor: "#848FA0",
            progressColor: "#848FA0",
            height: 20,
            container: recordBox.current!,
        });
        waveSurferRef.current = waveSurfer;

        waveRecordRef.current = waveSurfer.registerPlugin(
            RecordPlugin.create({
                renderRecordedAudio: false,
                scrollingWaveform: true,
                continuousWaveform: false,
                continuousWaveformDuration: 30, // optional
            }),
        );
        waveRecordRef.current.on("record-end", (blob: Blob) => {
            // const container = playBox.current!;
            // 转发数据
            emitter.emit("VOICE_END", {
                blob: blob,
                duration: durationRef.current
            });
            dispatch(showReview());
        });
        waveRecordRef.current.on('record-progress', (time: any) => {
            setDuration(time);
        });
        return () => {
            waveSurferRef.current?.destroy();
        };
    };
    useEffect(() => {
        if (recordVisible) {
            return createWaveSurfer();
        } else {
            setDuration(0);
        }
    }, [recordVisible]);
    // 分两个状态，录制状态，修正状态（此状态主要用来听声音，以及是否上传）
    return <Flex className="voice-record" align="center" gap="8px"
                 style={ { display: recordVisible ? "flex" : "none" } }>
        { duration ? <div className="progress">{ formattedTime(duration).join(":") }</div> : null }
        <div className="voice-track-box" ref={ recordBox }></div>
        <div className={ `voice-record-handler ${ recordState === RecordState.PAUSE && "active" }` }
             onClick={ handleRecord }>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"
                 fill="currentColor">
                <path
                    d="M8.00008 10.1053C9.39798 10.1053 10.518 8.97684 10.518 7.57895L10.5264 2.52632C10.5264 1.12842 9.39798 0 8.00008 0C6.60219 0 5.47377 1.12842 5.47377 2.52632V7.57895C5.47377 8.97684 6.60219 10.1053 8.00008 10.1053ZM13.179 7.57895C12.7837 7.57895 12.4699 7.90184 12.4047 8.29174C12.0466 10.4317 10.0989 11.8737 8.00008 11.8737C5.9013 11.8737 3.95359 10.4317 3.59551 8.29174C3.53027 7.90184 3.21646 7.57895 2.82114 7.57895C2.42582 7.57895 2.10039 7.9011 2.15298 8.2929C2.49443 10.8365 4.62845 12.86 7.15798 13.2379V15.1579C7.15798 15.623 7.535 16 8.00008 16C8.46517 16 8.84219 15.623 8.84219 15.1579V13.2379C11.3723 12.8676 13.5068 10.8439 13.8474 8.29293C13.8998 7.90109 13.5744 7.57895 13.179 7.57895Z"/>
            </svg>
        </div>
    </Flex>;
}

export default VoiceRecord;
