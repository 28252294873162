import { Form, Input, Flex, Button, message, Space, Image } from "antd";
import {
    PasswordIcon,
    UserIcon,
    // CloseIcon
} from "@/components/icon";
import { appLogin } from "@/api/im";
import { getImToken } from "@/api/im";
import { useEffect, useState } from "react";
import store2 from "store2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import chatIcon from "@/assets/svg/chat.svg";
import "./login.scss";
import { clearIMProfile, StorageKey } from "@/utils";
import { createUserAuth } from "@/utils/role.ts";

const { useMessage } = message;

function Login() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [messageApi, contextHolder] = useMessage();
    const [loading, setLoading] = useState(false);
    const handleLogin = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            if (values) {
                const res = await appLogin(values);
                if (res.code === 0) {
                    // 保存
                    store2.set(StorageKey.ORIGIN_TOKEN, res.data);
                    // 获取im登录需要的相关token
                    const { data } = await getImToken();
                    const checked = data.imCustomer || data.isGroupAdmin;
                    if (data && checked) {
                        const list = [];
                        // imCustomer
                        // imPermanent
                        if (data.imCustomer) {
                            list.push("imCustomer");
                        }
                        if (data.isGroupAdmin) {
                            list.push("groupAdmin");
                        }
                        store2.setAll({
                            [StorageKey.CHAT_TOKEN]: data.token,
                            [StorageKey.USER_ID]: data.userID,
                            [StorageKey.Role]: list,
                            [StorageKey.Auth]: createUserAuth(data),
                        });
                        messageApi.open({
                            type: "success",
                            content: t("common.loginSuccess")
                        });
                        navigate("/chat/message", { replace: true });
                    } else {
                        message.error({
                            content: t("common.loginError")
                        });
                    }

                } else {
                    message.error({
                        content: res.msg
                    });
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        clearIMProfile();
    }, []);
    return <div className="login">
        { contextHolder }
        <div className="city"></div>
        <div className="cloud1 cloud"></div>
        <div className="cloud2 cloud"></div>
        <div className="cloud3 cloud"></div>
        <div className="cloud4 cloud"></div>
        <Space className="project-text">
            <div className="left">
                <Image preview={ false } className="left-icon" src={ chatIcon } alt=""/>
            </div>
            <div className="right">{ t('common.title') }</div>
        </Space>
        <div className="login-box">
            <Flex justify="space-between" align="center">
                <div className="login-title">{ t("login.title") }</div>
                {/*<CloseIcon></CloseIcon>*/ }
            </Flex>
            <Form className="login-form" form={ form } onFinish={ handleLogin }>
                <Form.Item style={ { marginBottom: 40 } } name="userPhone"
                           rules={ [{ required: true, message: t("login.loginAccount") }] }>
                    <Input maxLength={ 10 }
                           prefix={ <UserIcon/> }
                           placeholder={ t("login.loginAccount") }
                           className="login-input"
                    ></Input>
                </Form.Item>
                <Form.Item name="password" rules={ [{ required: true, message: t("login.loginPassword") }] }>
                    <Input.Password prefix={ <PasswordIcon/> } placeholder={ t("login.loginPassword") }
                                    className="login-input"></Input.Password>
                </Form.Item>
                <Form.Item>
                    <Button loading={ loading } style={ { height: 43 } } type="primary" htmlType="submit" size="small"
                            block>{ t("login.loginIn") }</Button>
                </Form.Item>
            </Form>
        </div>
    </div>;
}

export default Login;
