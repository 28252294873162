import { Flex, Image, Space } from "antd";
import refreshIcon from "@/assets/svg/refresh.svg";
import eventIcon from "@/assets/svg/predictive.svg";
import moreIcon from "@/assets/svg/more.svg";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@/store";
import { setChatSettingVisible } from "@/store/slice/message.ts";
import { useParams } from "react-router-dom";
import { ConversationType } from "@chat/zim-sdk";
import useChatInit from "@/hooks/useChatInit.ts";
// import { OnlineState, UserStatusValue } from "@chat/component";
// import { useUserOnlineState } from "@/hooks/useUserOnlineState.ts";
import { showPredictive } from "@/store/slice/predictive.ts";
import { useMemo } from "react";

function Header() {
    const { t } = useTranslation();
    const { conversationType = ConversationType.Unknown } = useParams();
    const { currentConversation, chatSettingVisible } = useAppSelector(state => state.message);
    const { imGroupVo } = useAppSelector(state => state.room);
    const dispatch = useAppDispatch();
    const { chatInit } = useChatInit();
    const showSetting = () => {
        dispatch(setChatSettingVisible(!chatSettingVisible));
    };
    const renderName = () => {
        if (+conversationType === ConversationType.Peer) {
            // 需要对单人的在线状态进行订阅
            // 判断当前会话类型
            const alias = currentConversation.conversationAlias;
            const name = currentConversation.conversationName;
            if (alias) {
                return `${ alias } (${ name })`;
            }
            return name;
        }
        if (+conversationType === ConversationType.Room) {
            return imGroupVo?.groupName;
        }
    };
    const headerMenus = useMemo(() => {
        const menus = [
            {
                label: t("common.refresh"),
                icon: refreshIcon,
                onClick: () => {
                    chatInit();
                }
            },
        ];
        const event = {
            label: t("common.predictiveEvents"),
            icon: eventIcon,
            onClick: () => {
                dispatch(showPredictive());
            }
        };
        if (+conversationType === ConversationType.Room && imGroupVo?.groupType !== 3) {
            menus.push(event);
        }
        return menus;
    }, [imGroupVo, conversationType, chatInit]);


    return <Flex className="conversation-header" justify="space-between" align="center">
        <Space className="text">
            <span>
            { renderName() }
            </span>
            {/*{*/ }
            {/*    +conversationType === ConversationType.Peer &&*/ }
            {/*    <OnlineState state={ onlineStatus ? UserStatusValue.ON_LINE : UserStatusValue.OFF_LINE }></OnlineState>*/ }
            {/*}*/ }
        </Space>
        <Space size={ 20 } className={ "conversation-header-right" }>
            {
                headerMenus.map((item) => {
                    return <Space key={ item.label } align="center" onClick={ item.onClick }
                                  style={ { cursor: "pointer" } }>
                        <Image preview={ false } width={ 24 } height={ 24 } src={ item.icon }/>
                        <div>
                            { item.label }
                        </div>
                    </Space>;
                })
            }
            <Image style={ { cursor: "pointer" } } onClick={ showSetting } src={ moreIcon } width={ 24 } height={ 24 }
                   preview={ false }/>
        </Space>
    </Flex>;
}

export default Header;
