import { CUSTOM_TYPE } from "@/api/im.ts";
import { ConversationType } from "@chat/zim-sdk";
import { getIMProfile } from "@/utils/storage.ts";
// 普通权限1： 只能单聊，看不到群组，无法主动发起单聊
// 权限2: 能单聊。
// 可创建会话，可查看普通群，可普通群发送消息 可查看订阅号， 可订阅号发送消息，只能看到自己管理的群组，可看到所有群组，
export const NORMAL = 0b00000000;
export const READ_GROUP = 0b00000001; // 可查看普通群
export const WRITE_GROUP = 0b00000010; // 可普通群发送消息
export const READ_SUBSCRIBE = 0b00000100; // 可查看订阅号
export const WRITE_SUBSCRIBE = 0b00001000; // 可订阅号发送消息
export const ALL_GROUP = 0b00010000; // 查看所有可以查看的群， 否则只能看自己的群
export const CREATE = 0b00100000;
export type AuthType =
    typeof NORMAL
    | typeof READ_GROUP
    | typeof WRITE_GROUP
    | typeof READ_SUBSCRIBE
    | typeof WRITE_SUBSCRIBE
    | typeof CREATE
    | typeof ALL_GROUP;

// 充值客服--不可以看到所有群，不可以发起单聊
export const RECHARGE_SERVICE = NORMAL;
// 运营客服—只能看到所有群，但是无法发送消息，可创建单聊
export const OPERATIONS = READ_GROUP | WRITE_GROUP | READ_SUBSCRIBE | ALL_GROUP | CREATE;
// 游戏客服--可以看到除订阅群之外的所有群组， 可创建单聊
export const GAME_SERVICE = READ_GROUP | WRITE_GROUP | CREATE | ALL_GROUP | CREATE;
// 群管理员-- 只能看到自己管理的群包括订阅群，不可以发起单聊 rw
export const GROUP_ADMIN = READ_GROUP | WRITE_GROUP | READ_SUBSCRIBE | WRITE_SUBSCRIBE;

// 判断是否存在相应权限
export function hasAuth(auth: number, target: AuthType) {
    return (auth & target) === target;
}
export const authChat = (conversationType: keyof  typeof ConversationType, groupType: number) => {
    const imProfile = getIMProfile();
    if (+conversationType === ConversationType.Room) {
        // 如果是群组，判断是否可以发送消息
        // 判断是什么群，订阅群，普通群
        if (groupType === 3) {
            return hasAuth(imProfile.auth, WRITE_SUBSCRIBE);
        }
        return hasAuth(imProfile.auth, WRITE_GROUP);
    }
    return true;
};

export function createUserAuth(data: { imCustomer: CUSTOM_TYPE, isGroupAdmin: boolean }) {
    const { imCustomer, isGroupAdmin } = data;
    let auth: number = NORMAL;
    switch (imCustomer) {
        case CUSTOM_TYPE.GAME:
            auth = GAME_SERVICE;
            break;
        case CUSTOM_TYPE.OPERATION:
            auth = OPERATIONS;
            break;
        case CUSTOM_TYPE.RECHARGE:
            auth = RECHARGE_SERVICE;
            break;
        default:
            break;
    }
    if (isGroupAdmin) {
        auth |= GROUP_ADMIN;
    }
    return auth;
}
