import { createHttp, TypeResponse, TOKEN_TYPE } from "./http";
import { UserStatusValue } from "../utils";
import { objectToQueryString } from "@chat/shared";

const http = createHttp(
    import.meta.env.VITE_APP_IM_API_URL!,
    TOKEN_TYPE.ORIGIN
);
const apis = {
    // 登陆平台
    login: "/user/login",
    getReqParams: "/zgIm/getReqParams",
    // 获取客服
    getCustomer: "/zgIm/customers",
    // 修改修改im的状态
    setCustomer: "/zgIm/updateImStatus",
    // 获取gif
    getGif: "/zgIm/getImEmoticons",
    // 分页获取gif
    getGifPage: "/zgIm/getImEmoticonsPage",
    // 获取quickReply 快捷回复
    // 快捷回复获取列表
    quickList: "/quickReply/getList",
    // 快捷回复保存
    quickSave: "/quickReply/save",
    // 快捷回复编辑
    quickEdit: "/quickReply/edit",
    // 批量修改
    quickEditList: "/quickReply/editList",
    // 快捷回复删除
    quickDelete: "/quickReply/drop",
    // 上传文件
    uploadFile: "/quickReply/uploadFileV2",
    // 获取用户详细信息
    getImUserInfo: "/groupOperate/getImUserInfo",
    // 修改用户名称
    updateInfo: "/user/updateInfo",
    // 获取用户充值记录
    userRecharge: "/groupOperate/getImUserRecharge",
    // 发送消息，推送 ！只有单聊才调用 给app推送消息
    sendFbMessage: "/groupOperate/fb/sendUserMessage",
};


// 获取gif图片
export const getGifImage = () => {
    return http.get<any, TypeResponse<any>>(apis.getGif);
};
// 分页获取
export const getGifPage = (params: { pageNo: number, pageSize: number }) => {
    console.log(params, "getGifPage");
    return http.post<any, TypeResponse<any>>(apis.getGifPage, params);
};

export type ReplyType = {
    content?: string, // 内容
    sort?: number,
    remark?: string, // 备注
    otherUrl?: string,
    contentType?: 1 | 2, // 1话术 2UPI
    id?: string
};
export const getQuickList = (type: 1 | 2) => {
    return http.post<null, TypeResponse<{ content: ReplyType[] }>>(apis.quickList, {
        status: 1,
        contentType: type,
        pageNo: 1,
        pageSize: 1000
    });
};
export const addQuickList = (replay: ReplyType) => {
    return http.post<ReplyType, TypeResponse<any>>(apis.quickSave, replay);
};
export const delQuickList = (id: string | number) => {
    return http.post<ReplyType, TypeResponse<any>>(`${ apis.quickDelete }/${ id }`);
};
export const updateQuickList = (editList: ReplyType[]) => {
    return http.post<ReplyType[], TypeResponse<any>>(apis.quickEditList, {
        editList,
    });
};
export const updateQuick = (data: ReplyType) => {
    return http.post(apis.quickEdit, data);
};
export const uploadFile = (data: string) => {
    return http.post(apis.uploadFile, { data });
};

export type UPIType = {
    id?: number;
    address: string;
    qrCode: string;
    sort: number;
    content?: string;
};
export const getUPIList = () => {
    return http.post<null, TypeResponse<ReplyType[]>>(apis.quickList, {
        status: 1,
        contentType: 2,
        pageNo: 1,
        pageSize: 1000
    });
};
export const addUPIList = (UPI: ReplyType) => {
    return http.post<ReplyType, TypeResponse<any>>(apis.quickSave, UPI);
};
export const delUPIList = (id: number) => {
    return http.post<ReplyType, TypeResponse<any>>(`${ apis.quickDelete }/${ id }`);
};

export enum CUSTOM_TYPE {
    RECHARGE = "1",
    GAME = "2",
    OPERATION = "3" // 运营客服
}

export type IdentityItem = {
    imCustomer: CUSTOM_TYPE;
    imUserId: string;
    imUserStatus: UserStatusValue; //   * 0:离线 1:在线，2:忙碌，3:暂离，5常驻
    userAvatar: string;
    userId: number;
    imPermanent: "0" | "1" | "2" // 0 不是常驻 1 登陆显示常驻 2 匿名显示常驻
    userName: string;
}
export const getCustomer = () => {
    return http.get<any, TypeResponse<IdentityItem[]>>(apis.getCustomer);
};


export const setCustomer = (params: { imUserStatus?: UserStatusValue, imPermanent?: "0" | "1" }) => {
    // imCustomer 可选 1支付客服 2游戏客服
    return http.get<any, TypeResponse<any>>(apis.setCustomer, {
        params
    });
};

// 新接口
type ImTokenRes = {
    userID: string;
    token: string;
    imCustomer: CUSTOM_TYPE, //    * im客服 1支付客服 2游戏客服
    imUserStatus: UserStatusValue, //     * 0:离线 1:在线，2:忙碌，3:暂离，
    isGroupAdmin: boolean
}
// // 获取imtoken
export const getImToken = () => {
    return http.post<any, TypeResponse<ImTokenRes>>(apis.getReqParams, null);
};
export type ImUserInfo = {
    // 用户信息需要增加：用户来源，用户分组，vip等级，注册渠道，注册ip，手机号详细信息。
    agentFourUserId: number;
    agentOneUserId: number;
    agentSuperUserId: number;
    agentThreeUserId: number;
    agentTwoUserId: number;
    agentUserId: number;
    agentUserLevel: number;
    appType: string;
    channelId: string;
    createDate: number;
    createTime: number;
    deptId: number;
    deptName: string;
    imUserId: string;
    imUserStatus: string;
    inviteCode: string;
    isTest: string;
    lastLoginTime: string;
    latestActiveTime: number;
    level: number;
    memberLevel: number;
    packageId: number;
    rechargeCount: number;
    registerChannel: string;
    registerCityArea: string;
    registerIp: string;
    registerSource: string;
    registerVersionCode: number;
    updateTime: number;
    userAvatar: string;
    userGender: number;
    userId: number;
    userName: string;
    userPhone: string;
    platform: string;
    loginDevicec: string;
}
export const getImUserInfo = (imUserId: string) => {
    return http.post<any, TypeResponse<ImUserInfo>>(apis.getImUserInfo + "/" + imUserId);
};

export const appLogin = (payload: any) => {
    return http.post<any, TypeResponse<any>>(apis.login, {
        ...payload,
        // versionCode: 999,
        // channel: "h5",
        // channelId: "indusbet",
        // visitor: uid(32),
    });
};

export const updateInfo = (params: {
    userName?: string,
    userAvatar?: string
}) => {
    const queryString = objectToQueryString(params);
    return http.post<any, TypeResponse<any>>(`${ apis.updateInfo }?${ queryString }`);
};

export type RechargeProps = {
    rechargeCount: number,
    rechargeFailCount: number,
    rechargeFailCount5: boolean,
    rechargeChannelNames: string[]
}
export const getUserRecharge = (params: {
    imUserId: string
}) => {
    const { imUserId } = params;
    return http.post<any, TypeResponse<RechargeProps>>(`${ apis.userRecharge }/${ imUserId }`, params);
};

export const sendFbMessage = (params: {
    title: string;
    body: string;
    imageUrl: string;
    imUserId: string;
}) => {
    return http.post<any, TypeResponse<any>>(apis.sendFbMessage, params);
};
