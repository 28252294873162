import Content from "./Content";
import Header from "./Header";
import Footer from "./Footer";
import { Layout } from "antd";
import Quote from "@/pages/Message/feature/Conversation/Quote.tsx";
import VideoModal from "@/pages/Message/feature/Conversation/Modal/VideoModal.tsx";
import PasswordModal from "@/pages/Message/feature/Conversation/Modal/passwordModal"
import ImageTool from "./ImageTool";
import useChatInit from "@/hooks/useChatInit.ts";
import { useEffect } from "react";
import Predictive from "@/pages/Message/feature/Conversation/Predictive";

export default function Conversation() {
    const {
        chatInit,
        conversationID,
        conversationType
    } = useChatInit();
    useEffect(chatInit, [conversationID, conversationType]);
    return <Layout className="conversation">
        <Header/>
        <Content/>
        <ImageTool/>
        <Quote/>
        <Footer/>
        <VideoModal/>
        <PasswordModal/>
        <Predictive/>
    </Layout>;
}
