import { Avatar, Badge, Dropdown, Flex, List, MenuProps, message, Skeleton, Typography } from "antd";
import { formatConversionTime, replaceOssImage } from "@chat/shared";
import { useAppDispatch, useAppSelector } from "@/store";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import {
    addConversationListener,
    type ConversationListener,
    ConversationType,
    MessageReceiptStatus,
    removeConversationListener,
    deleteConversation, getConversationType,
} from "@chat/zim-sdk";
import LastMessage from "@/pages/Message/feature/Sider/LastMessage";
import { useEffect } from "react";
import {
    CustomConversationItem, getConversationList,
    removeConversation,
    setCurrentConversation,
    updateConversationList
} from "@/store/slice/message";
import { ReadStatus } from "@/components/icon";
import { useLatest } from "ahooks";
import { CreateChat } from "@/pages/Message/feature/Sider/CreateChat.tsx";
import { DeleteTwoTone } from "@ant-design/icons";
import store2 from "store2";

const { Paragraph } = Typography;
export default function Sider() {
    const params = useParams();
    const navigate = useNavigate();
    const { conversationID } = params;
    const { conversationLoading, conversationList, currentConversation } = useAppSelector(state => state.message);
    const { isLogining } = useAppSelector(state => state.common);

    const latestcurrentConversation = useLatest(currentConversation);
    const handleClick = (values: any) => {
        navigate(`/chat/message/${ values.conversationID }/${ values.type }`, { replace: true });
    };
    const dispatch = useAppDispatch();
    const listener = () => {
        const onConversationChanged: ConversationListener["onConversationChanged"] = (infoList) => {
            const currentConversation = latestcurrentConversation.current;
            infoList.forEach(item => {
                if (item.conversation.type === ConversationType.Peer) {
                    dispatch(updateConversationList(item.conversation));
                    if (currentConversation.conversationID === item.conversation.conversationID) {
                        dispatch(setCurrentConversation(item.conversation));
                    }
                }
            });
        };
        const onTotalUnreadMessageCountChanged: ConversationListener["onTotalUnreadMessageCountChanged"] = (totalUnreadMessageCount: number) => {
            console.log(totalUnreadMessageCount, "--->unread");
        };
        addConversationListener({
            onConversationChanged,
            onTotalUnreadMessageCountChanged
        });
        return () => removeConversationListener();
    };

    // 在这里进行监听会话变更
    useEffect(() => {
        if (isLogining) {
            return;
        }
        const removeListener = listener();
        return () => {
            removeListener();
        };
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            dispatch(getConversationList(conversationID));
        }, 120 * 1000);
        return () => clearInterval(timer);
    }, [conversationID]);
    const handleRemove = (item: CustomConversationItem, e: any) => {
        const event = e.domEvent;
        event.stopPropagation();
        if (item.type !== ConversationType.Peer) {
            message.warning("Currently, only single chats can be deleted!");
            return;
        }
        deleteConversation(item.conversationID, item.type).then(() => {
            // message.success("Operation successful.");
            // 清除本地
            dispatch(removeConversation(item));
            // 如果当前删除的id和当前的id相同
            if (conversationID === item.conversationID) {
                navigate("/chat/message", { replace: true });
            }
        }).catch(err => {
            message.error(err);
        });
    };
    const items: MenuProps['items'] = [{
        label: "Delete Conversation",
        icon: <DeleteTwoTone twoToneColor="#eb2f96"/>,
        key: "1"
    }];
    return <div className="sider">
        <CreateChat></CreateChat>
        <List itemLayout="horizontal" rootClassName="conversation-list" loading={ conversationLoading }
              dataSource={ conversationList }
              renderItem={ (item) => {
                  const classname = classnames({
                      "conversation-item": true,
                      "conversation-item__checked": conversationID === item.conversationID
                  });
                  const getReadStatus = () => {
                      if (!item.lastMessage) return false;
                      const type = getConversationType(item.conversationID);
                      if (type !== ConversationType.Room) {
                          // 判断发送发是不是自己，如果不是自己，判断状态
                          if (item.lastMessage.senderUserID === store2.get("userID")) return true;
                          return item.lastMessage.senderUserID !== store2.get("userID") && item.unreadMessageCount === 0;
                      } else {
                          return item.lastMessage.receiptStatus === MessageReceiptStatus.Done;
                      }
                  };
                  return <List.Item className={ classname } onClick={ handleClick.bind(null, item) }>
                      <Skeleton loading={ conversationLoading } avatar active>
                          <Dropdown menu={ { items, onClick: (e) => handleRemove(item, e) } }
                                    trigger={ ['contextMenu'] }>
                              <List.Item.Meta
                                  avatar={ <Badge size="small" count={ item.unreadMessageCount }>
                                      <Avatar
                                          style={ { backgroundColor: item.conversationAvatarUrl ? "transparent" : "rgb(0, 133, 255)" } }
                                          size={ 48 }
                                          src={ replaceOssImage(item.conversationAvatarUrl) }>{ item.conversationName.slice(0, 1) }</Avatar>
                                  </Badge> }
                                  title={ <Flex justify="space-between" className="title" align="center">
                                      <div
                                          className="showName">{ item.conversationAlias || item.conversationName }</div>
                                      <div
                                          className="time">{ formatConversionTime(item.lastMessage?.timestamp!, true) }</div>
                                  </Flex> }
                                  description={ item.lastMessage &&
                                      <Flex align="center" gap={ 2 }>
                                          <Paragraph ellipsis={ { rows: 1 } } className="item-text">
                                              <LastMessage message={ item.lastMessage }></LastMessage>
                                          </Paragraph>
                                          {
                                              <ReadStatus
                                                  read={ getReadStatus() }
                                                  style={ {
                                                      display: "inline-block",
                                                      width: 16,
                                                      height: 16,
                                                      flexShrink: 0
                                                  } }/>
                                          }
                                      </Flex>
                                  }
                              ></List.Item.Meta>
                          </Dropdown>
                      </Skeleton>
                  </List.Item>;
              } }></List>
    </div>;
}
