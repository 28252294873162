import { getIMProfile, initStore } from "@/utils";
import { useNavigate } from "react-router-dom";
import { login, addEvent, offEvent } from "@chat/zim-sdk";
import { useEffect } from "react";
import { useAppDispatch } from "@/store";
import { updateLogining } from "@/store/slice/common.ts";
import { getImToken } from "@/api/im";
import store2 from "store2";
import { TOKEN_TYPE } from "@/api/http.ts";
import { Modal } from "antd";

export default function useGlobalEvent() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    async function tryLogin() {
        // 设置当前登录中
        dispatch(updateLogining(true));
        const { imToken, imUserID } = getIMProfile();
        try {
            const userInfo = await login(imUserID, imToken);
            console.log(userInfo, "userInfo");
            if (userInfo) {
                initStore();
            } else {
                navigate("/login", {
                    replace: true
                });
            }
        } catch (error) {
            Modal.error({
                title: "Login failed",
                content: JSON.stringify(error),
                onOk: () => {
                    navigate("/login", {
                        replace: true
                    });
                }
            })
        } finally {
            dispatch(updateLogining(false));
        }
    }

    function loginCheck() {
        const { imToken, imUserID } = getIMProfile();
        if (!imToken || !imUserID) {
            // clearIMProfile();
            navigate("/login", { replace: true });
        } else {
            tryLogin();
        }
    }

    const setIMListener = () => {
        addEvent({
            getImToken,
            afterRefreshToken(token) {
                store2.set(TOKEN_TYPE.CHAT, token);
            },
            kickedOut: () => {
                Modal.confirm({
                    // 用户被踢下线
                    title: "You have been kicked out of the chat",
                    onOk() {
                        navigate("/login", { replace: true });
                    },
                    onCancel() {
                        navigate("/login", { replace: true });
                    }
                });
            },
        });
    };
    const disposeImListener = () => {
        offEvent();
    };

    useEffect(() => {
        loginCheck();
        setIMListener();
        return () => {
            disposeImListener();
        };
    }, []);
}
