import gameIcon from "@/assets/svg/gameIcon.svg";
import { Flex, Image } from "antd";

function Prediction() {
    return <Flex align="center" gap={4}>
        <Image width={ 12 } height={ 12 } style={ { display: "flex" } } src={ gameIcon } alt="" preview={ false }/>
        <span>Predictive Event</span>
    </Flex>;
}

export default Prediction;
