import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupMemberRole, ZIMRoomMemberInfo } from "@chat/zim-sdk";
import { RoomItem } from "@/api/room.ts";
export type GroupMemberItem = {
    userID: string;
    muteExpiredTime: number, // 禁言时间戳
    block: boolean, // 拉黑
    remark: string, // 备注
    blockId?: string;
    mutedId?: string;
    memberRole: GroupMemberRole
}
const initialState = {
    onlineMemberNum: 0,
    onlineMembers: [] as ZIMRoomMemberInfo[],
    adminList: [] as string[],
    // 登录账户在当前群聊的信息
    selfInGroupInfo: null as GroupMemberItem | null,
    imGroupVo: null as RoomItem | null, // 当前的聊天室
};

export const roomSlice = createSlice({
    name: "room",
    initialState,
    reducers: {
        setOnlineMemberNum(state, action: PayloadAction<number>) {
            state.onlineMemberNum = action.payload;
        },
        // 更新在线列表和人数
        updateOnlineMember(state, action: PayloadAction<{ type: "add" | "sub", data: ZIMRoomMemberInfo[] }>) {
            const { type, data } = action.payload;
            const len = data.length;
            if (type === "add") {
                state.onlineMemberNum += len;
                state.onlineMembers.push(...data);
            } else if (type === "sub") {
                state.onlineMemberNum -= len;
                const filterList = data.map(item => item.userID);
                state.onlineMembers = state.onlineMembers.filter(item => !filterList.includes(item.userID));
            }
        },
        setOnlineMembers(state, action: PayloadAction<ZIMRoomMemberInfo[]>) {
            state.onlineMembers = action.payload;
        },
        // 设置管理员
        setAdminList(state, action: PayloadAction<string[]>) {
            state.adminList = action.payload;
        },
        setSelfInGroupInfo(state, action) {
            state.selfInGroupInfo = action.payload;
        },
        setImGroupVo(state, action:PayloadAction<RoomItem>){
            state.imGroupVo = action.payload;
        },
        reset() {
            return initialState;
        }
    }
});

export const {
    setOnlineMemberNum,
    setOnlineMembers,
    updateOnlineMember,
    setAdminList,
    setSelfInGroupInfo,
    setImGroupVo,
    reset
} = roomSlice.actions;
export default roomSlice.reducer;
