import { ZIMMessage, useUserInfo } from "@chat/zim-sdk";
import photoIcon from "@/assets/svg/customCard.svg";
import { Flex } from "antd";

type CardProps = {
    message: ZIMMessage
};

export default function Card(props: CardProps) {
    const { senderUserID } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return <Flex gap={ 4 } align="center">
        { userInfo?.userName }:
        <img src={ photoIcon } className="item-text-image" alt=""/>
        Customer Card
    </Flex>;
}
