import { ZIMMessage, useUserInfo } from "@chat/zim-sdk";
import { Fragment } from "react";

type TextProps = {
    message: ZIMMessage
};

function Text(props: TextProps) {
    const { senderUserID, message } = props.message;
    const { userInfo } = useUserInfo(senderUserID);
    return <Fragment>{ userInfo?.userName }:{ message }</Fragment>;
}

export default Text;
