import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getForecastList, editForecastList, getCycleList as fetchCycleList } from "@/api/room.ts";
import { parseStr } from "@chat/shared";
import { EventResult } from "@chat/component";
import { message } from "antd";

export interface DataType {
    cycle: number;
    drawTime: number;
    gameRes: string;
    issueNo: string;
    status: number;
    gameType: "dice" | "3digit" | "color";
    forecastResults: EventResult;
    winningResults: EventResult;
    editable?: boolean;
}

export type RandomItem = {
    cycle: number,
    drawTime: number,
    gameType: "dice" | "3digit" | "color",
    groupId: string,
    issueNo: string,
    pageNo: number,
    pageSize: number,
    setRes: string,
    sourceId: number,
    status: number,
    editable?: boolean
}

const initialState = {
    visible: false,
    list: [] as DataType[],
    showGameType: [] as string[],
    gameTypeList: [
        {
            label: "Dice Game",
            value: "dice"
        },
        {
            label: "Color Game",
            value: "color"
        },
        // {
        //     label: "3 Digit Game",
        //     value: "3digit"
        // }
    ],
    gameType: "",
    cycleValue: "" as any,
    cycleList: [] as { label: string; value: any }[],
    forecastLoading: false,
    editLoading: false,
    paginate: {
        pageSize: 10,
        totalPage: 1,
        pageNo: 1,
        totalSize: 0,
    },
    randomSelectedRowKeys: [] as string[],
    randomLoading: false,
    randomList: [] as RandomItem[],
    randomVisible: false
};

export const getForecast = createAsyncThunk("predictive/getForecast", async (payload: {
    gameType: string;
    groupId: string;
    cycle: number;
    pageNo: number;
    pageSize: number
}) => {
    const { gameType, pageNo } = payload;
    const res = await getForecastList(payload);
    if (res.code === 0) {
        const { totalPages, totalSize, content } = res.data;
        return {
            totalPages,
            totalSize,
            pageNo,
            list: content.map((item: any) => {
                const baseData: Record<any, any> = {
                    ...item,
                };
                const parseResult = parseStr<EventResult>(item.forecastResults);
                if (item.gameRes == null) {
                    item.gameRes = "";
                }
                if (gameType === "dice") {
                    // 预测
                    // 结果
                    const [one, two, three] = item.gameRes.split(",");
                    baseData.forecastResults = [parseResult.one, parseResult.two, parseResult.three].join(",");
                    baseData.winningResults = {
                        one,
                        two,
                        three
                    };
                }
                if (gameType === "color") {
                    baseData.forecastResults = parseResult.number + "";
                    baseData.winningResults = {
                        number: item.digitResult
                    };
                }
                if (gameType === "3digit") {
                    baseData.forecastResults = [parseResult.one, parseResult.two, parseResult.three].join(",");
                    baseData.winningResults = {
                        one: item.digitResult
                    };
                }
                // 3digit
                return baseData;
            })
        };
    }
    return {
        totalPages: 1,
        totalSize: 0,
        pageNo: payload.pageNo,
        list: []
    };
});
// 获取周期列表「gameType」
export const getCycleList = createAsyncThunk("predictive/getCycleList", async (payload: {
    gameType: string
}) => {
    const res = await fetchCycleList(payload);
    if (res.code === 0) {
        const list = res.data.sort((a: number, b: number) => a - b).map((item: any) => {
            return {
                label: item + "min",
                value: item
            };
        });
        return {
            list
        };
    }
    return {
        list: []
    };
});
export const editPredictive = createAsyncThunk("predictive/editPredictive", async (payload: any) => {
    const { editList } = payload;
    const res = await editForecastList({
        editList: editList
    });
    return res;
});

export const predictiveSlice = createSlice({
    name: "predictive",
    initialState,
    extraReducers(builder) {
        builder.addCase(getForecast.pending, state => {
            state.forecastLoading = true;
        });
        builder.addCase(getForecast.fulfilled, ((state, action) => {
            state.forecastLoading = false;
            const { list, totalPages, totalSize, pageNo } = action.payload;
            state.list = list;
            if (list.length < state.paginate.pageSize) {
                // 没有下一页
            }
            const values = {
                ...state.paginate,
                totalPage: totalPages,
                totalSize: totalSize,
                pageNo
            };
            state.paginate = values;
        }));
        builder.addCase(getCycleList.fulfilled, (state, action) => {
            const { list } = action.payload;
            if (list.length) {
                state.cycleValue = list[0].value;
                state.cycleList = list;
            } else {
                state.cycleValue = "";
                state.cycleList = [];
            }
        });
        builder.addCase(editPredictive.pending, (state) => {
            state.editLoading = true;
        });
        builder.addCase(editPredictive.fulfilled, (state, action) => {
            if (action.payload.code === 0) {
                message.success(action.payload.msg);
            } else {
                message.error(action.payload.msg);
            }
            state.editLoading = false;
        });
        builder.addCase(editPredictive.rejected, state => {
            state.editLoading = false;
        });
    },
    reducers: {
        setList(state, action) {
            state.list = action.payload;
        },
        showPredictive(state) {
            state.visible = true;
        },
        closePredictive(state) {
            state.visible = false;
        },
        setPaginate(state, action: PayloadAction<typeof initialState.paginate>) {
            state.paginate = {
                ...state.paginate,
                ...action.payload
            };
        },
        setGameType(state, action) {
            state.gameType = action.payload;
        },
        setCycleValue(state, action) {
            state.cycleValue = action.payload;
        },
        setShowGameType(state, action) {
            state.showGameType = action.payload;
        },
        openRandom(state, action) {
            const { list } = action.payload;
            state.randomList = list;
            state.randomVisible = true;
        },
        closeRandom(state) {
            state.randomVisible = false;
            state.randomSelectedRowKeys = [];
            state.randomList = [];
        },
        saveRandomList(state, action) {
            state.randomList = action.payload;
        },
        setRandomSelectedRowKeys(state, action) {
            const { selectedRowKeys } = action.payload;
            state.randomSelectedRowKeys = selectedRowKeys;
        },
        reset() {
            return initialState;
        }
    }
});

export const {
    showPredictive,
    setList,
    closePredictive,
    setGameType,
    setCycleValue,
    setShowGameType,
    openRandom,
    closeRandom,
    saveRandomList,
    setRandomSelectedRowKeys
} = predictiveSlice.actions;
export default predictiveSlice.reducer;
