import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import "normalize.css/normalize.css";
import "@/assets/fonts/font.css";
import "@/locales";
import { setup as SDKSetup } from "@chat/zim-sdk";
import "@chat/component/dist/component.css";

SDKSetup(parseInt(import.meta.env.VITE_APP_ZIM_APP_ID));

ReactDOM.createRoot(document.getElementById('root')!).render(
    <App/>
);
