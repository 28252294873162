import cls from "./imageTool.module.scss";
import { Image } from "antd";
import emojiIcon from "@/assets/svg/emoj-tool.svg";
import gifIcon from "@/assets/svg/gif-tool.svg";
import { useEffect, useRef, useState } from "react";
import classname from "classnames";
import { useAppDispatch, useAppSelector } from "@/store";
import { setToolVisible, updateInputText } from "@/store/slice/message";
import useSendMessage from "@/hooks/useSendMessage";
import { ExpressType } from "@/pages/Message/feature/Conversation/ImageTool/type";
import Emoji from "@/pages/Message/feature/Conversation/ImageTool/Emoji";
import GifBox from "@/pages/Message/feature/Conversation/ImageTool/GifBox";
import { AnimatePresence, motion } from "framer-motion";
import { createImageMessage } from "@chat/zim-sdk";
import { useParams } from "react-router-dom";

function ImageTool() {
    const { sendMessage } = useSendMessage();
    const { conversationID = "" } = useParams();
    const { inputText, toolVisible } = useAppSelector(state => state.message);
    const [expressType, setExpressType] = useState(ExpressType.EMOJI);
    const dispatch = useAppDispatch();
    const list = [
        {
            type: ExpressType.EMOJI,
            icon: emojiIcon,
        },
        {
            type: ExpressType.GIF,
            icon: gifIcon
        }
    ];
    const chooseItem = async (type: ExpressType, value: string) => {
        if (type === ExpressType.EMOJI) {
            dispatch(updateInputText(inputText + value));
        } else {
            // 创建一个文件对象
            const message = await createImageMessage({
                fileDownloadUrl: value,
                largeImageDownloadUrl: value,
                thumbnailDownloadUrl: value
            });
            // 发送信息
            sendMessage({
                conversationID: conversationID,
                message
            });

        }
        dispatch(setToolVisible(false));
    };
    const renderTools = () => {
        switch (expressType) {
            case ExpressType.EMOJI:
                return <Emoji chooseItem={ chooseItem }/>;
            case ExpressType.GIF:
                return <GifBox chooseItem={ chooseItem }></GifBox>;
        }
    };
    const tool = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (toolVisible) {
            // 先聚焦， 这样点击其他地方可以隐藏
            tool.current?.focus();
        }
    }, [toolVisible]);
    const handleShowTool = (type: ExpressType) => {
        setExpressType(type);

    };
    return <div className={ cls.tool } ref={ tool } role="button" tabIndex={ 0 } onBlur={ () => {
        setTimeout(() => {
            dispatch(setToolVisible(false));
        }, 200);
    } }>
        <div className={ cls.content } id="imageTool">
            {
                renderTools()
            }
        </div>
        <div className={ cls.menus }>
            {
                list.map(item => {
                    return <div
                        key={ item.type }
                        className={ classname(cls.menusItem, { [cls.menusItem__active]: expressType === item.type }) }>
                        <Image
                            className={ classname(cls.menusItemImg) }
                            onClick={ handleShowTool.bind(null, item.type) }
                            preview={ false }
                            src={ item.icon }/>
                    </div>;
                })
            }
        </div>
    </div>;
}

function WithAnimalTool() {
    const { toolVisible } = useAppSelector(state => state.message);
    return <AnimatePresence>
        {
            toolVisible && <motion.div
                exit={ { opacity: 0, } }
                initial={ { opacity: 0 } }
                animate={ { opacity: 1 } }
            ><ImageTool/></motion.div>
        }
    </AnimatePresence>;
}

export default WithAnimalTool;
