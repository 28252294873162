import { Flex, Form, FormInstance, Input, InputRef, Table, TableProps } from "antd";
import { renderForecastResults } from "@chat/component";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { DataType, editPredictive, getForecast, setList } from "@/store/slice/predictive.ts";
import { useAppDispatch, useAppSelector } from "@/store";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

const EditableContext = createContext<FormInstance<any> | null>(null);
type ColumnTypes = Exclude<TableProps<any>['columns'], undefined>;

export function EditableRow({ index, ...props }: any) {
    const [form] = Form.useForm();
    return <Form form={ form } component={ false }>
        <EditableContext.Provider value={ form }>
            <tr { ...props }></tr>
        </EditableContext.Provider>
    </Form>;
}

export const EditableCell = ({
                                 title,
                                 editable,
                                 children,
                                 dataIndex,
                                 record,
                                 gameType,
                                 handleSave,
                                 ...restProps
                             }: any) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;
    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        if (record.status !== 0) {
            return;
        }
        setEditing(!editing);
        const value = record[dataIndex];
        form.setFieldsValue({
            [dataIndex]: value,
        });
    };
    const save = async () => {
        try {
            // save 一次记录一下修改的list
            const formValues = await form.validateFields();
            formValues[dataIndex] = formValues[dataIndex].trim();
            setEditing(!editing);
            handleSave({
                ...record,
                ...formValues,
            });
            console.log({
                ...record,
                ...formValues
            });

        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={ {
                    margin: 0,
                } }
                rules={ [
                    // { message: `Please check the format`, pattern: /^[0-9](,[0-9])*$/},
                    () => ({
                        validator(_, value) {
                            if (gameType === "color") {
                                if (!value.match(/^[0-9]?$/)) {
                                    return Promise.reject("The Color field is invalid");
                                }
                                return Promise.resolve();
                            }
                            if (gameType === "dice") {
                                if (value.length === 0) return Promise.resolve();
                                if (!value.match(/^[0-9](,[0-9]){2}$/)) {
                                    return Promise.reject("Dice can only have 3 numbers");
                                }
                                // if (value.length !== 6) {
                                //     return Promise.reject("Dice can only have 3 numbers");
                                // }
                                return Promise.resolve();
                            }
                            return Promise.resolve();
                        },
                    }),
                ] }
                name={ dataIndex }
            >
                <Input style={ { width: "100px" } }
                       ref={ inputRef }
                       size="small"
                       onPressEnter={ save }
                       onBlur={ save }
                />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={ {
                    paddingInlineEnd: 24,
                    cursor: record.status !== 0 ? "not-allowed" : "pointer"
                } }
                onClick={ toggleEdit }
            >
                { children }
            </div>
        );
    }
    return <td { ...restProps }>{ childNode }</td>;
};

function PredictiveTable() {
    const dispatch = useAppDispatch();
    const { conversationID = "" } = useParams();
    const { list, forecastLoading, paginate, cycleValue, gameType } = useAppSelector(state => state.predictive);
    const columns: (ColumnTypes[number] & { editable?: boolean }) [] = [
        {
            title: "DrawTime",
            dataIndex: "drawTime",
            editable: true,
            key: "drawTime",
            render(val) {
                return dayjs(val).format("DD-MM-YY hh:mm:ss");
            }
        },
        {
            title: "ISSUE",
            dataIndex: "issueNo",
            key: "issueNo"
        },
        {
            title: "FORECAST RESULTS",
            dataIndex: "forecastResults",
            key: "forecastResults",
            onCell: record => ({
                record,
                editable: true,
                dataIndex: "forecastResults",
                title: "forecastResults",
                gameType: record.gameType,
                handleSave,
            }),
            render(val: string, row: DataType) {
                if (!val) {
                    return "-";
                }
                return <Flex>
                    {
                        renderForecastResults({
                            gameType: row.gameType,
                            result: val
                        })
                    }
                </Flex>;
            }

        },
        {
            title: "WINNING RESULTS",
            dataIndex: "winningResults",
            key: "winningResults",
            render(val, row) {
                if ([0, 3].includes(row.status)) {
                    return "-";
                }
                return <Flex>
                    {
                        renderForecastResults({
                            gameType: row.gameType,
                            result: val
                        })
                    }
                </Flex>;
            }
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            render(val) {
                const obj = {
                    color: "#8696A5",
                    text: "-"
                };
                switch (val) {
                    case 1:
                        obj.color = "#0085FF";
                        obj.text = "HIT";
                        break;
                    case 2:
                        obj.text = "Missed";
                        break;
                    case 3:
                        obj.color = "#03DA0B";
                        obj.text = "In Progress";
                        break;
                    default:
                        break;
                }
                return <span style={ { color: obj.color, fontWeight: 500 } }> { obj.text }</span>;
            }
        },
    ];
    const handleSave = (row: DataType) => {
        const newData = [...list];
        const index = newData.findIndex((item) => row.issueNo === item.issueNo);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
            editable: true
        });
        // // 组装一下
        const editItem = {
            issueNo: row.issueNo,
            groupId: conversationID,
            drawTime: row.drawTime,
            gameType: row.gameType,
            setRes: row.forecastResults
        };
        dispatch(editPredictive({
            editList: [editItem]
        }))
        dispatch(setList(newData));
    };
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        },
    };
    return <Table
        loading={ forecastLoading }
        style={ { marginTop: 12 } }
        columns={ columns }
        dataSource={ list }
        size="small"
        rowKey={ (record) => record.issueNo }
        components={ components }
        rowClassName={ (_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark' }
        pagination={ {
            total: paginate.totalSize,
            pageSize: paginate.pageSize,
            showTotal: (total) => `Total ${ total } items`,
            showSizeChanger: false,
            current: paginate.pageNo,
            onChange(pageNo) {
                dispatch(getForecast({
                    groupId: conversationID,
                    gameType,
                    cycle: cycleValue,
                    pageNo,
                    pageSize: paginate.pageSize
                }));
            }
        } }
    ></Table>;
}

export default PredictiveTable;
