import {
    Button,
    Col,
    Divider,
    Drawer,
    Image,
    // message,
    Row,
    Select,
    Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { memo, useEffect, useMemo } from "react";
import closeIcon from "@/assets/svg/close.svg";
import cls from "./predictive.module.scss";
import {
    RandomIcon,
    // SaveIcon
} from "@/components/icon";
import { useAppDispatch, useAppSelector } from "@/store";
import {
    closePredictive,
    getForecast,
    // editPredictive,
    getCycleList,
    setGameType,
    setCycleValue,
    setShowGameType,
    openRandom
} from "@/store/slice/predictive";
import PredictiveTable from "@/pages/Message/feature/Conversation/Predictive/PredictiveTable.tsx";
import { randomForecast } from "@/api/room.ts";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import Random from "@/pages/Message/feature/Conversation/Predictive/Random.tsx";
import { ReloadOutlined } from "@ant-design/icons";


function PredictiveEvents() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    let { conversationID = "" } = useParams();
    const { imGroupVo } = useAppSelector(state => state.room);
    const {
        visible,
        paginate,
        gameType,
        gameTypeList,
        cycleList,
        forecastLoading,
        cycleValue,
        // editLoading,
        showGameType,
        // list
    } = useAppSelector(state => state.predictive);
    const { loading: randomForecastLoading, run: runRandomForecast } = useRequest(randomForecast, {
        onSuccess: (res) => {
            if (res.code === 0) {
                dispatch(openRandom({ list: res.data || [] }));
            }
        }
    });
    const handleRandom = () => {
        runRandomForecast({
            gameType,
            groupId: conversationID,
            cycle: cycleValue
        });
    };
    const handleChangeType = (val: string) => {
        // 获取周期列表
        dispatch(setGameType(val));
        dispatch(getCycleList({ gameType: val })).then((res) => {
            const { list } = res.payload as any;
            if (list.length > 0) {
                dispatch(getForecast({
                    gameType: val,
                    cycle: list[0].value,
                    pageNo: 1,
                    pageSize: paginate.pageSize,
                    groupId: conversationID
                }));
            }
        });
    };
    const handleCycleChange = (val: number) => {
        dispatch(setCycleValue(val));
        // 请求预测列表
        dispatch(getForecast({
            groupId: conversationID,
            gameType: gameType,
            cycle: val,
            pageNo: 1,
            pageSize: paginate.pageSize
        }));
    };
    useEffect(() => {
        if (imGroupVo?.groupId) {
            // gameCodes
            console.log(imGroupVo.gameCodes, "imGroupVo.gameCodes");
            const gameCodes = imGroupVo.gameCodes?.split(",").filter(Boolean) || [];
            if (!gameCodes.length) {
                gameCodes.push("dice");
                gameCodes.push("color");
            }
            // 去除了dice和color的其他游戏

            const filterCodes = gameCodes.filter(item => ["dice", "color"].indexOf(item) !== -1);
            dispatch(setShowGameType(filterCodes));
            handleChangeType(filterCodes[0]);
        }
    }, [conversationID, imGroupVo]);
    const filterList = useMemo(() => {
        const list: any[] = [];
        showGameType.forEach(item => {
            const gameTypeItem = gameTypeList.find(games => {
                return games.value === item;
            });
            if (gameTypeItem) {
                list.push(gameTypeItem);
            }
        });
        return list;
    }, [showGameType, showGameType]);
    // const savePredictive = () => {
    //     if (!conversationID) {
    //         message.error("Please select a group");
    //         return;
    //     }
    //     const editableList = list.filter((item) => item.editable);
    //     // 组装一下
    //     const editList = editableList.map((item: any) => {
    //         return {
    //             issueNo: item.issueNo,
    //             groupId: conversationID,
    //             drawTime: item.drawTime,
    //             gameType: item.gameType,
    //             setRes: item.forecastResults
    //         };
    //     });
    //     dispatch(editPredictive({
    //         editList
    //     })).then(() => {
    //         refresh();
    //     });
    // };
    const refresh = () => {
        // 重新拉取数据
        dispatch(getForecast({
            groupId: conversationID,
            gameType: gameType,
            cycle: cycleValue,
            pageNo: paginate.pageNo,
            pageSize: paginate.pageSize
        }));
    };
    // 680
    return <Drawer width={ 750 } className={ cls.predictive }
                   extra={ <Image onClick={ () => dispatch(closePredictive()) } src={ closeIcon }
                                  style={ { cursor: "pointer" } }
                                  preview={ false }/> }
                   closable={ false }
                   title={ t("common.predictiveEvents") }
                   open={ visible }
                   onClose={ () => dispatch(closePredictive()) }
                   placement="right"
    >
        <Row>
            <Col>
                <Space split={ <Divider type="vertical" style={ { height: 16, background: "#E5E6EB" } }/> }>
                    <div className={ cls.gameName }>
                        {
                            filterList.length === 1 ? <div>{ filterList[0].label }</div> : <Space>
                                <div style={ { color: "#848FA0", fontWeight: 500, fontSize: 14 } }>Game</div>
                                <Select options={ filterList }
                                        style={ { width: 120 } }
                                        disabled={ filterList.length === 1 }
                                        value={ gameType }
                                        onChange={ handleChangeType }
                                        suffixIcon={ <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13"
                                                          viewBox="0 0 12 13"
                                                          fill="none">
                                            <path d="M3 5L6 8L9 5" stroke="#848FA0" strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg> }
                                />
                            </Space>
                        }

                    </div>
                    <Space className={ cls.gameplay }>
                        <div>Gameplay</div>
                        <Select size="middle" suffixIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13"
                                 fill="none">
                                <path d="M3 5L6 8L9 5" stroke="#848FA0" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        } style={ { width: 86 } } value={ cycleValue } options={ cycleList }
                                onChange={ handleCycleChange }>
                        </Select>
                    </Space>
                </Space>
            </Col>
            <Col style={ { marginLeft: "auto" } }>
                <Space>
                    <Button loading={ forecastLoading } icon={ <ReloadOutlined/> } onClick={ refresh }
                            shape="circle"></Button>
                    <Button loading={ randomForecastLoading } icon={ <RandomIcon/> }
                            onClick={ handleRandom }>Random</Button>
                    {/*<Button loading={ editLoading } type="primary" icon={ <SaveIcon/> }*/ }
                    {/*        onClick={ savePredictive }>Save</Button>*/ }
                </Space>
            </Col>
        </Row>
        <PredictiveTable/>
        <Random/>
    </Drawer>;
}

export default memo(PredictiveEvents);
