import { ConversationType, ZIMMessage } from "@chat/zim-sdk";
import { updateOneConversation } from "@/store/slice/message.ts";
import { useAppDispatch } from "@/store";

export const useUpdateRoomLastMsg = () => {
    const dispatch = useAppDispatch();
    const updateRoomLastMsg = (message: ZIMMessage) => {
        if (message.conversationType !== ConversationType.Room) {
            return;
        }
        dispatch(updateOneConversation({
            lastMessage: message,
            unreadMessageCount: 0,
            conversationID: message.conversationID
        }));
    };
    return {
        updateRoomLastMsg
    };
};
