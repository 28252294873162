import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";
import { CustomTypeValue } from "@chat/zim-sdk";

const http = createHttp(
    import.meta.env.VITE_APP_IM_API_URL!,
    TOKEN_TYPE.ORIGIN
);
const apis = {
    // 获取在线聊天室列表
    getRoom: "/zgIm/groupList",
    // 获取历史消息
    getRoomMsg: "/groupOperate/getGroupMsgPage",
    // 获取聊天室的详情
    // 获取自己个人和聊天室的信息
    getUserGroupInfo: "/groupOperate/getUserGroupInfo",
    // 获取聊天室管理员列表
    getAdminList: "/zgIm/groupAdminList",
    // 获取个人分析信息（包含用户游戏分析）
    getUserStatistics: "/zgIm/getUserStatistics",
    // 自定义系统消息发送
    sendCustomMsg: "/groupOperate/roomMsg",
    // 编辑房间里的群成员
    editPerson: "/groupOperate/edit",
    savePersonAttr: "/groupOperate/save",
    // 删除房间成员属性
    removePersonAttr: "/groupOperate/drop",
    // 点赞
    like: "/userLike/save",
    cancelLike: "/userLike/drop",
    authRoom: "/groupPassRecord/save",
    // 预测时间列表
    forecastList: "/game/predict/list",
    // 批量修改预测列表
    editForecastList: "/game/predict/edit/list",
    // 周期列表 /game/predict/cycle/list
    cycleList: "/game/predict/cycle/list",
    // 随机预测/game/predict/random
    randomForecast: "/game/predict/random",
    // 变更在线房已读和订阅数
    groupUserLastMsg: "/groupUserLastMsg/save"
};
export type RoomItem = {
    groupId: string;
    gameCodes: string; // 当前的群属于那个游戏
    groupInfo: string; // 简介
    groupName: string;
    groupNotice: string; // 通知
    groupLogo: string;
    createTime: number;
    updateTime: number;
    fillPass: boolean; // 是否已经输入密码
    groupType: 1 | 2 | 3; // '类型 1公开 2私密 3频道'
    lastMsg: any;
    isGroupAdmin: boolean;
    subscribers: number;
    unreadMsgCount: number; // 未读数
}
export const getRoomList = (id: string) => {
    return http.post<RoomItem[]>(apis.getRoom + "/" + id);
};
type GroupOperateVos = {
    groupId: string,
    imUserId: string,
    operateType: 1 | 2, // 操作类型 1禁言 2拉黑
    remark: string, // 备注
    endTime: number // 结束时间戳
    id: string;
}
export type HistoryItem = {
    winner: string;
    price: string;
    game: string;
}
export type UserGroupItem = {
    imGroupVo: RoomItem;
    ifAdmin: boolean;
    fillPass: boolean;
    imGroupOperateVos: GroupOperateVos[];
    imUserGameNoticeVos: HistoryItem[];
    lastMsgList?: any[]
}
export const getUserGroupInfo = (params: { groupId: string; imUserId: string }) => {
    return http.post<any, TypeResponse<UserGroupItem>>(apis.getUserGroupInfo, params);
};

export const getAdminList = (params: { groupId: string }) => {
    return http.get(apis.getAdminList, { params });
};

export const getUserStatistics = (params: { imUserId: string; imGroupId: string }) => {
    return http.post<any, TypeResponse<any>>(apis.getUserStatistics, params);
};

export const sendCustomMsg = (params: {
    groupId: string;
    reqData: string;
    imUserId: string;
    subMsgType: CustomTypeValue;
    msgIds?: string[]
}) => {
    return http.post<any, TypeResponse<any>>(apis.sendCustomMsg, params);
};
// 添加属性
export const savePersonAttr = (params: {
    groupId: string;
    operateType: 1 | 2; // 操作类型 1禁言 2拉黑
    imUserId: string;
    endTime?: number; // 结束时间戳
}) => {
    return http.post<any, TypeResponse<any>>(apis.savePersonAttr, params);
};
// 删除属性
export const removePersonAttr = (params: {
    id: string
}) => {
    return http.post<any, TypeResponse<any>>(apis.removePersonAttr + "/" + params.id);
};

// 点赞
export const setUserLike = (params: { targetUserId: string, imUserId: string; state: boolean }) => {
    const { targetUserId, imUserId, state } = params;
    return http.post<any, TypeResponse<any>>(state ? apis.cancelLike : apis.like, {
        targetUserId,
        imUserId
    });
};

export const applyPasswordRoom = (params: {
    groupId: string,
    groupPass: string,
    imUserId: string
}) => {
    return http.post<any, TypeResponse<any>>(apis.authRoom, params);
};
export const getRoomMsg = (params: {
    pageNum: number;
    pageSize: number;
    groupId: string;
    imUserId: string
}) => {
    return http.post<any, TypeResponse<any>>(apis.getRoomMsg, params);
};
export const getForecastList = (params: { gameType: string; cycle: number; pageNo: number; pageSize: number }) => {
    return http.post<any, TypeResponse<any>>(apis.forecastList, params);
};
export type EditItem = {
    issueNo: string;
    groupId: string;
    drawTime: string;
    gameType: string;
    setRes: string; // dice 1,5,6 color 1 3digit 0,8,3
}
export const editForecastList = (params: { editList: EditItem[] }) => {
    return http.post<any, TypeResponse<any>>(apis.editForecastList, params);
};
export const getCycleList = (params: { gameType: string }) => {
    return http.post<any, TypeResponse<any>>(apis.cycleList, params);
};
export const randomForecast = (params: { gameType: string; groupId: string; cycle: number }) => {
    return http.post<any, TypeResponse<any>>(apis.randomForecast, params);
};

export const groupUserLastMsg = (params: {
    groupId: string;
    imUserId: string;
    msgId: string | null
}) => {
    return http.post(apis.groupUserLastMsg, params);
};
