import { Layout, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store";
import MessageItemCop from "./MessageItem";
import Notice from "@/pages/Message/feature/Conversation/Notice";
import SystemMessage from "@/pages/Message/feature/Conversation/MessageItem/SystemMessage";
import emitter from "@/utils/emitter";
import { useDebounceFn } from "ahooks";
import ToBottom from "@/components/icon/ToBottom";
import useMarkRead from "@/hooks/useMarkRead";
import { AnimatePresence, motion } from "framer-motion";
import {
    ChatMessageItem,
    ConversationType,
    decorationMessage,
    getConversationType,
    normalizeHistoryMessage,
    SystemMessageTypes,
    ZIMCustomMessage,
    MessageType,
    CustomType,
    getMessageList
} from "@chat/zim-sdk";
import { getIMProfile, StorageKey } from "@/utils";
import store2 from "store2";
import { addHistoryMessage } from "@/store/slice/message.ts";
import { getRoomMsg } from "@/api/room.ts";


function Content() {
    const virtuosoRef = useRef<HTMLDivElement>(null);
    const { conversationID = "" } = useParams();
    const isFetch = useRef(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [markRead] = useMarkRead(conversationID!);
    const { messageList, messageLoading } = useAppSelector(state => state.message);
    const getShowMessageList = (messageList: ChatMessageItem[]) => {
        // 过滤掉群分享掉卡片东西
        return messageList.filter(item => {
            if (item.type !== MessageType.Custom) return true;
            return (item as ZIMCustomMessage).subType !== CustomType.Game;
        });
    };
    const { imUserID } = getIMProfile();
    // 获取当前会话详细信息
    const rowRenderer = (rows: ChatMessageItem[]) => {
        return rows.map(data => {
            if (SystemMessageTypes.includes(data.type as any)) {
                return <SystemMessage data={ data } key={ data.messageID }></SystemMessage>;
            }
            return <MessageItemCop data={ data } key={ data.messageID } isSelf={ imUserID === data.senderUserID }/>;
        });
    };
    const handleToBottom = (config?: { behavior?: "smooth" | "auto", index?: number }) => {
        const { behavior = "auto" } = config || {};
        setTimeout(() => {
            virtuosoRef.current?.lastElementChild?.scrollIntoView({
                // index: index ? index : messageList.length - 1,
                behavior
            });
            // 标记已读
            markRead();
        }, 50);
    };
    const [bottomBtn, setBottomBtn] = useState(false);
    // 导航栏滚动到最底部显示按钮
    const { run: handleScroll } = useDebounceFn((scroll = true) => {
        if (messageList.length === 0) return; // 切换的时候list为0 防止切换造成的滚动产生问题
        const target = document.querySelector("#virtual") as HTMLDivElement;
        const limitValue = 20;
        if (!target) return;
        const scrolltop = target.scrollTop;
        if (scrolltop <= 20 && scroll) {
            loadMore();
        }
        const offsetHeight = target.offsetHeight;
        const scrollHeight = target.scrollHeight;
        if (scrolltop + offsetHeight + limitValue < scrollHeight) {
            if (bottomBtn) return;
            setBottomBtn(true);
        } else {
            if (!bottomBtn) return;
            // 标记已读
            markRead();
            setBottomBtn(false);
        }
    }, { wait: 500 });
    // 监听到数据更新
    const addMessage = () => {
        if (!bottomBtn) {
            handleToBottom({ behavior: 'smooth' });
        }
    };

    const paginationConfig = useRef({
        pageNum: 2,
        pageSize: 20,
        end: false
    });
    const handlerAddScroll = (oldScrollTop: number, oldScrollHeight: number, container: HTMLDivElement, scrollTarget: HTMLDivElement) => {
        requestAnimationFrame(() => {
            container.scrollTop = oldScrollTop + (container.scrollHeight - oldScrollHeight);
            scrollTarget?.scrollIntoView({
                behavior: "smooth"
            });
            isFetch.current = false;
        });
    };
    const loadMore = () => {
        // 根据聊天场景，选择适合的方法
        if (getConversationType(conversationID) === ConversationType.Peer) {
            loadMorePeer();
        }
        if (getConversationType(conversationID) === ConversationType.Room) {
            loadMoreRoom();
        }
    };
    const loadMorePeer = () => {
        if (isFetch.current || paginationConfig.current.end) return;
        const firstMessage = messageList.find(item => item.senderUserID);
        isFetch.current = true;
        setFetchLoading(true);
        getMessageList(conversationID, { nextMessage: firstMessage, count: 20 }).then(res => {
            setFetchLoading(false);
            if (!res.length) {
                paginationConfig.current.end = true;
                isFetch.current = false;
                return;
            }
            dispatch(addHistoryMessage(res));
            const messageID = res[res.length - 1]?.messageID;
            if (messageID) {
                const container = document.querySelector("#virtual") as HTMLDivElement;
                const target = document.querySelector(`div[data-key="${ messageID }"]`) as HTMLDivElement;
                // 恢复滚动位置
                handlerAddScroll(container.scrollTop, container.scrollHeight, container, target);
            }
        }).catch(err => {
            console.error(err);
            isFetch.current = false;
            setFetchLoading(false);
        });
    };
    const loadMoreRoom = () => {
        const config = paginationConfig.current;
        if (config.end || isFetch.current) return;
        isFetch.current = true;

        setFetchLoading(true);
        getRoomMsg({
            groupId: conversationID,
            imUserId: store2.get(StorageKey.USER_ID),
            pageNum: config.pageNum,
            pageSize: config.pageSize
        }).then(res => {
            if (res.data.length < config.pageSize) {
                paginationConfig.current = {
                    ...paginationConfig.current,
                    end: true
                };
            } else {
                paginationConfig.current.pageNum++;
            }
            setFetchLoading(false);
            const historyList = res.data.map(normalizeHistoryMessage);
            const newList = decorationMessage(historyList as ZIMCustomMessage[]);
            dispatch(addHistoryMessage(newList));
            const messageID = newList[newList.length - 1]?.messageID;
            if (messageID) {
                const container = document.querySelector("#virtual") as HTMLDivElement;
                const target = document.querySelector(`div[data-key="${ messageID }"]`) as HTMLDivElement;
                // 恢复滚动位置
                handlerAddScroll(container.scrollTop, container.scrollHeight, container, target);
            }
        }).catch((err) => {
            console.error(err);
            isFetch.current = false;
            setFetchLoading(false);
        });
    };


    useEffect(() => {
        emitter.on("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
        emitter.on("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        return () => {
            emitter.off("CHAT_LIST_SCROLL_TO_BOTTOM", handleToBottom);
            emitter.off("CHAT_LIST_SCROLL_BY_SHOW_BTN", addMessage);
        };
    }, [addMessage]);
    useEffect(() => {
        handleScroll(false);
    }, [messageList]);
    useEffect(() => {
        if (!messageLoading) {
            handleToBottom();
        }
    }, [messageLoading]);
    useEffect(() => {
        isFetch.current = false;
        paginationConfig.current = {
            pageNum: 2,
            pageSize: 20,
            end: false
        };
    }, [conversationID]);
    return <Layout.Content className="conversation-content">
        <Notice/>
        <div className="conversation-content-main">
            <Spin spinning={ messageLoading } wrapperClassName="content-spin">
                <div ref={ virtuosoRef } id="virtual" className="message-main" onScroll={ () => handleScroll(true) }>
                    {
                        fetchLoading && <Spin tip="loading" style={ { top: 40 } }>
                            <div></div>
                        </Spin>
                    }
                    {
                        rowRenderer(getShowMessageList(messageList))
                    }
                </div>
            </Spin>

            <AnimatePresence>

                {
                    bottomBtn &&
                    <motion.div exit={ { opacity: 0, y: -20, x: -30 } }
                                initial={ { opacity: 0, y: 20, x: -30 } }
                                animate={ { opacity: 1, y: 0, x: -30 } }
                                onClick={ () => handleToBottom() }>
                        <ToBottom className="chat-to-bottom"/>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    </Layout.Content>;
}

export default Content;
