import { formattedTime } from "@chat/shared";
import { Image, Space, Typography } from "antd";
import MessageState from "@/pages/Message/feature/Conversation/MessageItem/MessageState.tsx";
import voiceIcon from "@/assets/svg/voice-message.svg";
import { useAppDispatch, useAppSelector } from "@/store";
import cls from "../quote.module.scss";
import { showVideoModal } from "@/store/slice/modal.ts";
import videoPlay from "@/assets/svg/video-play.svg";
import classname from "classnames";
import {
    ChatMessageItem,
    CustomType,
    MessageType,
    ZIMAudioMessage,
    ZIMCustomMessage,
    ZIMImageMessage,
    ZIMMessageRepliedInfo,
    ZIMTextMessage,
    ZIMVideoMessage,
    useUserInfo
} from "@chat/zim-sdk";

const { Paragraph } = Typography;

type QuoteMessageProps = {
    data: ChatMessageItem;
}

function QuoteMessage(props: QuoteMessageProps) {
    const { data } = props;
    const { userInfo } = useAppSelector(state => state.common);
    const isSelf = data.senderUserID === userInfo?.userID;
    const { userInfo: sendUser } = useUserInfo(data.senderUserID);
    const dispatch = useAppDispatch();
    const renderContent = (quotoMessage: ZIMMessageRepliedInfo) => {
        const messageInfo = quotoMessage.messageInfo;
        switch (messageInfo.type) {
            case MessageType.Text:
                return <Paragraph ellipsis={ { rows: 2 } }>{ (messageInfo as ZIMTextMessage).message }</Paragraph>;
            case MessageType.Audio:
                const time = formattedTime((messageInfo as ZIMAudioMessage).videoDuration! * 1000).join(":");
                return <div className="voice-quoto-message">
                    <Image preview={ false } className="icon" src={ voiceIcon }/>
                    <span>voice ({ time })</span>
                </div>;
            case MessageType.Image:
                return <Space>
                    <Image width={ 100 } src={ (messageInfo as ZIMImageMessage).thumbnailDownloadUrl }></Image>
                </Space>;
            case MessageType.Video:
                return <div className="quote-message-video">
                    <Image onClick={ () => dispatch(showVideoModal(messageInfo)) } preview={ false }
                           width={ 100 } src={ (messageInfo as ZIMVideoMessage).videoFirstFrameDownloadUrl }/>
                    <Image preview={ false } src={ videoPlay } rootClassName="quote-message-video__play"></Image>
                </div>;
            case MessageType.Custom:
                const customMessage = messageInfo as ZIMCustomMessage;
                if (customMessage.subType === CustomType.Card) {
                    return <span>card</span>;
                }
                return <span>{ customMessage.subType }</span>;
            // return <ShareCards self={ isSelf } data={ quotoMessage } quote={ true }></ShareCards>;
            default:
                return "error quote type";
        }
    };
    //
    return <>
        <div className={ classname(cls.replyQuote, isSelf && cls.replyQuote__self) }
             style={ { padding: 0, marginTop: 4 } }>
            <div className={ cls.replyContent } style={ { background: "#fff", padding: "4px 6px" } }>
                <div
                    className={ cls.replyContent__name }>{ isSelf ? "YOU" : sendUser?.userName }</div>
                <div className="quote-message-content">{ renderContent(data.repliedInfo!) }</div>
            </div>
        </div>
        <div className="quote-message-quoteText">
            {
                data.message
            }
            <MessageState data={ data } className="message-state__right"/>
        </div>
    </>;
}

export default QuoteMessage;
