import { NewChat } from "@/components/icon";
import { useState } from "react";
import { ConversationType, createConversation, getUserInfo, IUserInfo, queryConversation } from "@chat/zim-sdk";
import { Avatar, Empty, Flex, Input, message, Modal } from "antd";
import { getIMProfile, getPrefixPackage } from "@/utils";
import { setCurrentConversation, updateConversationList } from "@/store/slice/message.ts";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/store";
import { CREATE, hasAuth } from "@/utils/role.ts";

export function CreateChat() {
    const [visible, setVisible] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchUserId, setSearchUserId] = useState("");
    const [searchUser, setSearchUser] = useState<IUserInfo | null>(null);
    const openModal = () => {
        setSearchUser(null);
        setSearchUserId("");
        setVisible(true);
    };
    const handleSearch = async (value: string) => {
        try {
            const prefix = getPrefixPackage();
            const packageUserId = prefix + value;
            const res = await getUserInfo(packageUserId, true);
            setSearchUser(res.user);
        } catch (err) {
            setSearchUser(null);
            message.warning("User does not exist");
        }
    };
    const handleNewChat = () => {
        if (searchUser) {
            const { imUserID } = getIMProfile();
            if (searchUser.userID === imUserID) {
                message.warning("Cannot chat with oneself!");
                return;
            }
            // 先判断是否存在会话，如果不存在，那么创建一个
            navigate(`/chat/message/${ searchUser.userID }/${ ConversationType.Peer }`, { replace: true });
            queryConversation(searchUser.userID).then(() => {
                // 存在会话
            }).catch(err => {
                if (err.code === 6000603) {
                    // 不存在会话，需要创建
                    // 不存在
                    const conversation = createConversation({
                        conversationID: searchUser.userID,
                        conversationName: searchUser.userName,
                        type: ConversationType.Peer,
                        conversationAvatarUrl: searchUser.userAvatarUrl
                    });
                    dispatch(updateConversationList(conversation));
                    dispatch(setCurrentConversation(conversation));
                }
            });
            setVisible(false);
        }
    };
    console.log(hasAuth(getIMProfile().auth, CREATE), "create");
    if (!hasAuth(getIMProfile().auth, CREATE)) return null;
    return <div className="newChat">
        <div className="newChat-box" onClick={ openModal }>
            <NewChat width={ 16 } height={ 16 }></NewChat>
            <span>New Chat</span>
        </div>
        <Modal width={ 360 }
               open={ visible }
               title="New Chat"
               okText="Start Chat"
               okButtonProps={ {
                   disabled: !searchUser
               } }
               onCancel={ () => setVisible(false) }
               onOk={ handleNewChat }>
            <Input.Search value={ searchUserId }
                          onChange={ val => setSearchUserId(val.target.value) }
                          onSearch={ handleSearch }
                          placeholder="Please enter the User ID"></Input.Search>
            {
                searchUser ?
                    <Flex style={ { borderRadius: 4, background: "#f5f5f5", padding: "12px 0", marginTop: 12 } }
                          align="center" justify="center" gap={ 8 }>
                        <Avatar size={ 36 } src={ searchUser?.userAvatarUrl }/>
                        <div style={ { fontWeight: 500, color: "#191c1f" } }>
                            <div style={ { fontSize: 14 } }>ID: { searchUser?.userID }</div>
                            <div style={ { fontSize: 12, color: "#848fa0" } }>Username: { searchUser?.userName }</div>
                        </div>
                    </Flex> : <Empty style={ { marginTop: 12 } }/>
            }

        </Modal>
    </div>
}
