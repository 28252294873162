import { Flex, Modal, Table, TableProps } from "antd";
import { renderForecastResults } from "@chat/component";
import {
    RandomItem,
    saveRandomList,
    closeRandom,
    setRandomSelectedRowKeys,
    editPredictive, getForecast
} from "@/store/slice/predictive.ts";
import { useAppDispatch, useAppSelector } from "@/store";
import dayjs from "dayjs";
import { EditableCell, EditableRow } from "@/pages/Message/feature/Conversation/Predictive/PredictiveTable.tsx";
import { useParams } from "react-router-dom";

type ColumnTypes = Exclude<TableProps<any>['columns'], undefined>;


function Random() {
    const {
        randomList,
        randomVisible,
        randomSelectedRowKeys,
    } = useAppSelector(state => state.predictive);
    const {
        paginate,
        gameType,
        cycleValue,
    } = useAppSelector(state => state.predictive);
    const dispatch = useAppDispatch();
    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        },
    };
    let { conversationID = "" } = useParams();
    const handleSave = (row: RandomItem) => {
        const newData = [...randomList];
        const index = newData.findIndex((item) => row.issueNo === item.issueNo);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        dispatch(saveRandomList(newData));
    };
    const rowSelection: TableProps<RandomItem>['rowSelection'] = {
        type: "checkbox",
        onChange: (selectedRowKeys: React.Key[]) => {
            dispatch(setRandomSelectedRowKeys({
                selectedRowKeys,
            }));
        },
        getCheckboxProps: (record: RandomItem) => ({
            name: record.issueNo,
        })
    };
    const columns: (ColumnTypes[number] & { editable?: boolean }) [] = [
        {
            title: "DrawTime",
            dataIndex: "drawTime",
            editable: true,
            key: "drawTime",
            render(val) {
                return dayjs(val).format("DD-MM-YY hh:mm:ss");
            }
        },
        {
            title: "ISSUE",
            dataIndex: "issueNo",
            key: "issueNo"
        },
        {
            title: "FORECAST RESULTS",
            dataIndex: "setRes",
            key: "setRes",
            width: 150,
            onCell: record => ({
                record,
                editable: true,
                dataIndex: "setRes",
                title: "setRes",
                gameType: record.gameType,
                handleSave,
            }),
            render(val: string, row: RandomItem) {
                if (!val) {
                    return "-";
                }
                return <Flex>
                    {
                        renderForecastResults({
                            gameType: row.gameType,
                            result: val
                        })
                    }
                </Flex>;
            }

        },
    ];
    const saveSelectedRandom = () => {
        const editList = randomList.filter(item => {
            return randomSelectedRowKeys.includes(item.issueNo);
        });
        dispatch(editPredictive({
            editList: editList
        })).then(() => {
            dispatch(closeRandom());
            // 更新列表
            dispatch(getForecast({
                groupId: conversationID,
                gameType: gameType,
                cycle: cycleValue,
                pageNo: 1,
                pageSize: paginate.pageSize
            }));
        });
    };
    return <Modal title="Recent 50 Periods"
                  open={ randomVisible }
                  width={ 600 }
                  onCancel={ () => dispatch(closeRandom()) }
                  onOk={ saveSelectedRandom }
    >
        <Table
            scroll={{ x: 'max-content', y: 41 * 10 }}
            // loading={ forecastLoading }
            style={ { marginTop: 12 } }
            columns={ columns }
            dataSource={ randomList }
            size="small"
            pagination={false}
            rowKey={ (record) => record.issueNo }
            components={ components }
            rowSelection={ { ...rowSelection, selectedRowKeys: randomSelectedRowKeys } }
            rowClassName={ (_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark' }
        ></Table>
    </Modal>;
}

export default Random;
