import { toggleToolVisible } from "@/store/slice/message.ts";
import { useAppDispatch } from "@/store";
import { Face as FaceIcon } from "@/components/icon";
import { BaseToolProps } from "@/pages/Message/feature/Conversation/Tool/type.ts";
import classNames from "classnames";

function Face(props: BaseToolProps) {
    const { disabled } = props;
    const dispatch = useAppDispatch();
    const handlerClick = () => {
        if (disabled) return;
        dispatch(toggleToolVisible());
    };
    return <FaceIcon className={ classNames("tools-item", disabled && "tools-item-disable") }
                     onClick={ handlerClick }
    ></FaceIcon>;
}

export default Face;
