import { Avatar, Button, Input, message, Modal, Popover, Space, Typography } from "antd";
import { logout as SDKLogout } from "@chat/zim-sdk";
import cls from "./layout.module.scss";
import { clearIMProfile, copyableProps } from "@/utils";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store";
import { replaceOssImage } from "@chat/shared";
import { setSelfUser, updateSelfUser } from "@/store/slice/common.ts";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useRequest } from "ahooks";
import { updateInfo } from "@/api/im";

const { Paragraph } = Typography;


function UserAvatar() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useAppDispatch();
    const { userInfo } = useAppSelector(state => state.common);
    const [changeNameInput, setChangeNameInput] = useState("");
    const [changeNameVisible, setChangeNameVisible] = useState(false);
    const handlerEditor = () => {
        setChangeNameInput(userInfo?.userName || '');
        setChangeNameVisible(true);
    };
    const { loading, run } = useRequest(updateInfo, {
        manual: true,
        onSuccess: (res) => {
            if (res.code === 0) {
                messageApi.success(res.msg);
                setChangeNameVisible(false);
                dispatch(updateSelfUser({
                    userName: changeNameInput
                }));
            } else {
                messageApi.error(res.msg);
            }
        },
        onError: (error) => {
            messageApi.error(JSON.stringify(error));
        }
    });
    const handleUserNameOk = () => {
        run({
            userName: changeNameInput
        });
    };
    const AvatarContent = () => {
        const logOut = async () => {
            // 可能已经退出登录了
            clearIMProfile();
            try {
                SDKLogout();
            } finally {
                // 清除用户信息
                dispatch(setSelfUser(null));
                navigate("/login", { replace: true });
            }
        };
        return <div className={ cls.content }>
            <div className={ cls.content__item }>
                <div className={ cls.content__item__label }>ID</div>
                <Paragraph copyable={ copyableProps(userInfo?.userID || '') }
                           className={ cls.content__item__value }>{ userInfo?.userID }</Paragraph>
            </div>
            <div className={ cls.content__item }>
                <div className={ cls.content__item__label }>Nickname</div>
                <Space className={ cls.content__item__value }>
                    { userInfo?.userName }
                    <EditOutlined color="#848FA0" onClick={ handlerEditor }
                                  className={ cls.content__item__value__edit }/>
                </Space>
            </div>
            <Button block className={ cls.logout } danger onClick={ logOut }>Log out</Button>
        </div>;
    };


    return <>
        { contextHolder }
        <Popover content={ <AvatarContent/> } trigger="click">
            <Avatar style={ { cursor: "pointer" } } size={ 68 }
                    src={ replaceOssImage(userInfo?.userAvatarUrl) }></Avatar>
        </Popover>
        <Modal width={ 360 } title="change Name" open={ changeNameVisible } okText="Confirm changes"
               onCancel={ () => setChangeNameVisible(false) }
               onOk={ handleUserNameOk } confirmLoading={ loading }>
            <Input value={ changeNameInput } allowClear onChange={ (e) => setChangeNameInput(e.target.value) }></Input>
        </Modal>
    </>;
}

export default UserAvatar;
